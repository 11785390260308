.m_blogs-posts {
  a.small.hollow:has(.icon--chevron-left),
  a.small.hollow.js-with-back {
    @extend .link--go-back;
  }
  .ql-editor.ql-reset-decidim {
    margin: 0;
    padding: 0;
  }
  .view-side .card__content {
    .button_to,
    .button_to > .button,
    & > div {
      width: 100%;
    }
    .buttons__row {
      flex-direction: column;
      align-items: flex-start;
      justify-content: stretch;
      & > :first-child {
        margin-bottom: 1rem;
      }
      & > .column,
      & .button-group,
      & > .column > .button {
        width: 100%;
      }
    }
  }
  .author-data__extra {
    button:hover {
      text-decoration: underline;
    }
    .icon--flag {
      @extend .body-5;
      @extend .body-5-link;
      width: 14px;
      height: 14px;
    }
  }

  .card__header {
    padding: 0;
  }
  .card {
    padding: 2rem;
    p:last-child {
      margin-bottom: 0;
    }
    .card__title {
      margin-bottom: 0.75rem;
    }
    .card__author {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;
      transform: translateY(-9px);
    }
  }
  #most-commented {
    strong {
      font-weight: normal;
      @extend .body-4;
      @extend .body-4-link;
    }
    .section-heading {
      @extend .mini-title;
      color: var(--emphasis-med);
      margin-bottom: 1rem;
    }
    .author-data.author-data--nopadding {
      @extend .body-5;
      color: var(--emphasis-low);
      a {
        @extend .body-5;
        @extend .body-5-link;
      }
    }
  }
}
