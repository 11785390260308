#conversations {
  min-height: 60vh;
}
.unread_message__counter {
  background-color: var(--primary) !important;
}
.m_messaging-conversations {
  &.m_messaging-conversations--index {
    margin-bottom: 64px;
    .callout.secondary {
      margin-top: 1rem;
    }
  }
  min-height: unset;
  .unread_message__counter {
    background-color: var(--primary) !important;
    position: absolute;
    top: 1.3rem;
    right: 0rem;
    margin-right: 0.9rem;
  }
}
.m_messaging-conversations.m_messaging-conversations--show {
  min-height: 20vh;
}
.m_messaging-conversations,
.tabs-panel.conversations {
  .card--list__author {
    display: block;
    .author-data__main,
    .author.author--flex,
    .author__name--container {
      width: 100%;
      min-width: 100%;
    }
    .author.author--flex {
      .author__avatar {
        flex: 1;
      }
      > div:last-child {
        flex: 6;
      }
    }
  }
  .title-action {
    &:not(.columns) {
      margin-bottom: 2rem;
    }
    h1.heading1.title-action__title {
      @extend .heading-3;
      font-weight: normal;
    }
    h4.heading4 {
      @extend .body-2;
      font-weight: normal;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
  button.button {
    @extend .btn;
    @extend .btn--primary;
    cursor: pointer;
  }

  .card--widget {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--grey2);
    &:last-child {
      border-bottom: 0;
    }
    &:first-child,
    &:last-child {
      border-radius: $global-radius;
    }
    &:first-child:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .card__link {
      @extend .body-6;
    }
    div.card__link {
      cursor: normal;
      color: var(--emphasis-med);
    }
    .card-data .card-data__item:first-child {
      background-color: var(--white);
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
    .card-data .card-data__item:hover {
      text-decoration: none !important;
      cursor: normal;
    }
    .card-data__item.card--list__item.card-data__item--expand {
      margin-left: 1.5rem;
      > div {
        @extend .body-5;
        strong {
          width: auto;
          @extend .body-5;
          @extend .body-5-medium;
          text-transform: none;
          letter-spacing: normal;
          color: var(--emphasis-low);
        }
        & > strong {
          color: var(--emphasis-high);
        }
        & > .muted,
        & > .text-small {
          margin-top: 0.5rem;
          display: inline-block;
        }
        & > .text-small {
          @extend .body-5;
          color: var(--emphasis-med);
          strong {
            color: var(--emphasis-high);
          }
        }
      }
    }
  }
}
