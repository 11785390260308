.accountability{
  .card__link--block{
    display: block;
  }

  .card--meta{
    span{
      margin-right: .5rem;
    }
  }
}
