.result-view .result-meta .result-meta--data {
  @extend .body-4;
}
.result-view .result-meta {
  @extend .body-4;
}

.accountability {
  .result-view .result-meta {
    @extend .body-4;
  }

  .result-view .result-meta .result-meta--data {
    @extend .body-4;
  }

  .intro {
    ul {
      margin-bottom: 1rem;
    }

    .progress-level {
      @extend .card;
      margin-bottom: 0;

      p {
        margin-bottom: 1rem;
        display: inline-block;
        margin-right: 0.5rem;
        @extend .body-2;
        @extend .body-2-semibold;
        color: var(--emphasis-low);
      }

      .icon {
        background: var(--grey2);
        border-radius: 50%;
        fill: white;
        padding: 0.15rem;
      }

      .progress {
        max-width: 85%;
        margin-bottom: 0;
      }

      .progress-figure {
        @extend .heading-2;
        color: var(--emphasis-lowest);
        margin-top: 2.25rem;
      }
    }
  }

  .scope-filters {
    div {
      @extend .mini-title;
      margin-bottom: 0.5rem;
    }

    span {
      margin-right: 0.5rem;
    }

    ul {
      display: inline;
    }

    li.active {
      background-color: var(--grey2);
    }
  }

  .description {
    margin-bottom: 1rem;
  }

  .title-action {
    margin-bottom: 0.5rem;
  }
}

.result-view {
  .title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    flex-direction: column;

    .icon {
      fill: $secondary;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-bottom: 0.5rem;

      @include breakpoint(medium) {
        margin-right: 0.75rem;
        margin-top: 0.5rem;
      }
    }

    @include breakpoint(medium) {
      flex-direction: row;
    }
  }

  .progress-level {
    background: $white;
    border-radius: 4px;
    padding: 1.5rem;
    border: 1px solid var(--grey2);

    .progress-label {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 0.5rem;

      .progress-text {
        @extend .body-2;
        @extend .body-2-semibold;
        color: var(--emphasis-low);
      }

      .progress-figure {
        line-height: 1;
        font-size: 2.25rem;
        font-weight: 600;
        margin-top: 2.25rem;
      }
    }
  }

  .result-description div {
    margin-bottom: 1rem;
  }

  .result-meta {
    margin-bottom: 0.5rem;

    .result-meta--label {
      font-weight: inherit;
      @extend .mini-title;
    }
  }

  hr {
    width: 100%;
    margin: 0 0 3rem;
  }

  .timeline {
    .timeline__info {
      background-color: transparent;
      border: none;
    }
  }
}
