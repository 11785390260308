.process-header__phase{
  background: $white;
  padding: $global-padding;
  margin: $global-margin 0;
  border-radius: $global-radius;

  .button{
    margin-top: $global-margin * .75;
    margin-bottom: 0;
  }
}

.process-header__phase--simple{
  padding: .5rem 1rem;

  @include breakpoint(medium){
    padding: 2rem 1rem;
  }
}

.phase-title{
  display: block;
  font-weight: $global-weight-bold;
  font-size: 1rem;
  line-height: 1;

  @include breakpoint(medium){
    font-size: rem-calc(20);
  }

  .is-inactive &{
    color: $muted;
  }
}

.phase-date{
  color: $muted;
  font-weight: 600;
  font-size: 90%;
}

.process-header__progress{
  margin-bottom: $global-margin * .5;

  ol{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
  }

  li{
    margin: 0;
    padding: 0;
    position: relative;
    width: 1rem;
    text-align: center;

    &:first-child{
      width: auto;
    }

    &::after{
      $line-thickness: 2px;

      content: "";
      display: block;
      height: $line-thickness;
      width: 100%;
      background-color: $medium-gray;
      position: absolute;
      top: calc(50% - (#{$line-thickness} / 2));
      z-index: 0;
    }

    span{
      display: block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: $medium-gray;
      z-index: 1;
      margin-left: auto;
    }
  }

  .phase-item--past{
    &::after{
      background-color: var(--primary);
    }

    span{
      background-color: var(--primary);
      opacity: 1;
    }
  }

  // arrow
  .phase-item--current{
    span::before{
      content: " ";
      height: 0;
      position: absolute;
      width: 0;
      border: 4px solid transparent;
      border-top-color: var(--primary);
      top: -8px;
      left: calc(50% - 1px);
      z-index: 2;
    }

    &:first-child span::before{
      left: 0;
    }
  }
}

.phase-current{
  font-weight: $global-weight-normal;
  font-size: rem-calc(12);
  text-transform: uppercase;
  color: $muted;
}

.process-phase{
  @extend .process-header__phase;

  padding: 0;

  .process-header__progress{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid $muted;
    padding: .5rem 1rem;

    ol{
      flex: 1;
    }

    .phase-current{
      margin-left: 1rem;
    }
  }
}

.process-header__body{
  padding: 1rem 1rem 1.5rem;

  .process-header__link{
    margin-top: .5rem;
  }
}

.process-header__button{
  .button{
    border-color: var(--primary);
  }
}

.phase-title--initiatives{
  @extend .phase-title;

  font-size: 1.125rem;
  margin: 0 0 .5rem;
}

.phase-subtitle--initiatives{
  @extend .phase-title;

  font-size: rem-calc(15);
}
