.process-header__phase {
  padding: 1rem;

  > div {
    margin-bottom: 1rem;
  }
}

.process-header__progress {
  > ol {
    margin-bottom: 0.75rem;
  }
}
