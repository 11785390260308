// Helpers

.list-reset{
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-inline{
  > li{
    display: inline-block;
    vertical-align: top;
  }

  > dt{
    clear: both;
    margin-right: .3em;
  }

  > dt,
  > dd{
    float: left;
    margin-bottom: 0;
  }
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;

  > :first-child{
    margin-right: .5rem;
  }

  > :last-child{
    margin-left: .5rem;
  }
}

// flex--cc:
// justify-content: center;
// align-items: center;
.flex--cc{
  @include flex;
  @include flex-align($x: center, $y: middle);
}

// flex--sbc:
// justify-content: space-between;
// align-items: center;
.flex--sbc{
  @include flex;
  @include flex-align($x: justify, $y: middle);
}

// flex--sbe:
// justify-content: space-between;
// align-items: flex-end;
.flex--sbe{
  @include flex;
  @include flex-align($x: justify, $y: bottom);
}

// flex--sc:
// justify-content: flex-start;
// align-items: center;
.flex--fsc{
  @include flex;
  @include flex-align($x: left, $y: middle);
}

// flex--fsb:
// justify-content: flex-start;
// align-items: baseline;
.flex--fsb{
  @include flex;
  @include flex-align($x: left, $y: null);

  align-items: baseline;
}

// flex--cb:
// justify-content: center;
// align-items: baseline;
.flex--cb{
  @include flex;
  @include flex-align($x: center, $y: null);

  align-items: baseline;
}

.scroll{
  max-height: 50vh;
  overflow-y: scroll;
}

// Make parent relative to absolute positioning children
.absolutes{
  position: relative;

  .left,
  .right,
  .top,
  .bottom{
    position: absolute;
  }

  .left{
    left: 0;
  }

  .right{
    right: 0;
  }

  .top{
    top: 0;
  }

  .bottom{
    bottom: 0;
  }

  .left,
  .right{
    &.center{
      @include vertical-center;
    }
  }

  .top,
  .bottom{
    &.center{
      @include horizontal-center;
    }
  }
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}

.aspect-ratio-16-9{
  @extend .absolutes;

  padding-bottom: 56.25%;

  > *{
    @extend .left;
    @extend .top;
    @extend .bottom;
    @extend .right;
  }
}
