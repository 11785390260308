/** Reporting proposals **/
.dynamic-map-instructions{
    &.column {
        margin-left: 0;
    }
    h3 {
        @extend .body-2;
    }
    p {
        padding-left: 0;
    }
}
.photos_container {
    margin-top: 1rem;
}

#address_input .address-fill .input-group {
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
}

#address_input .address-fill .user-device-location {
    flex: 1;
    min-width: 220px;
    button.button {
        border-top-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0;
    }
}

#address_input .address-fill {
    margin-bottom: 1rem;
    div.autoComplete_wrapper {
    flex: 3;
    input {
        max-height: 40px;
        border-top-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 4px;

    }
}
}