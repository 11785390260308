.row.collapse.order-by {
  align-items: center;
  justify-content: center;
}

.order-by__dropdown {
  transform: translateY(-2px);
  z-index: 1;
}

.order-by__tab {
  @extend .body-4;
  @extend .body-4-link;
  padding-left: 1rem;

  &::after {
    content: "";
    display: none;
    border-left: none;
  }

  &.is-active {
    color: var(--primary);
    text-decoration: underline;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type::after {
    border-left: none;
  }
}
