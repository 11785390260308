//Foundation progress extension

.progress{
  position: relative;
}

.progress-meter--minimum{
  background: repeating-linear-gradient(-55deg, rgba(white, .2), rgba(white, .2) 8px, rgba(black, .03) 8px, rgba(black, .03) 16px);
  position: absolute;
}

.progress-meter-text--right{
  left: auto;
  min-width: 100%;
  transform: translate(0, -50%);
  text-align: right;
  padding-left: 1rem;
  padding-right: .5rem;
}
