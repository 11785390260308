/* Variables */
$message-padding: 1rem;

/* Messages */

.unread_message__counter{
  $counter-size: 25px;
  $counter-font-size: $counter-size - 12px;

  background-color: var(--primary);
  border-radius: 50%;
  color: $white;
  font-size: $counter-font-size;
  font-weight: 600;
  margin-left: auto;
  margin-right: 5%;
  width: $counter-size;

  span{
    display: block;
    line-height: $counter-size;
    text-align: center;
  }
}

/* Message form */

.add-message{
  @extend .card;

  padding: $message-padding;

  .button{
    margin-bottom: 0;
  }
}
