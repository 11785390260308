#metrics {
  #metrics .column:last-child:not(:first-child),
  #metrics .columns:last-child:not(:first-child),
  #metrics .pull-right {
    float: left;
  }
  & > .row.mb-sm {
    max-width: 72% !important;
    margin-bottom: 48px !important;
  }
  .metric-title {
    margin-top: rem-calc(32);
    @extend .heading-4;
    color: var(--emphasis-med);
  }
  .metric-description {
    color: var(--emphasis-med);
    @extend .body-4;
  }
  .metric-description + .pull-right .text-small {
    @extend .body-4;
  }
  .pull-right,
  .column:last-child:not(:first-child),
  .columns:last-child:not(:first-child) {
    float: none;
  }
  .row.column.text-center {
    text-align: left !important;
  }
}
