.o-profiles .card--user_group_membership {
  .card__header {
    margin-top: 1.5rem;
    width: 100%;
  }
  .card__button {
    flex: 0;
  }
  .card__content > p {
    padding-left: 1rem;
    strong {
      @extend .label;
      @extend .label--primary;
      color: var(--white);
      transform: translateY(-2px);
    }
  }
}

.card--user_group,
.card--user {
  padding-top: 1rem;
}
.card--user_group .card__button {
  flex: 0;
}

.m_groups.m_groups--new {
  form.new_group .card {
    padding: 1rem;
    input[type="file"] {
      margin-bottom: 0;
    }
    .field img {
      margin-bottom: 1rem;
    }
    .user-group-fields > strong {
      margin-top: 1.75rem;
      margin-bottom: 0.5rem;
      display: block;
    }
    .actions {
      margin-top: 1.5rem;
    }
    .form-input-extra-before ~ .help-text {
      margin-top: -0.5rem;
    }
  }
}
