.process-header {
  background-image: none;

  .heading2 {
    font-weight: var(--font-weight-bold);
  }
}

.process-header__inner {
  position: relative;
  min-height: 14rem;
  @include flex;
  @include flex-align($x: center);
}

.process-header__main {
  background-size: cover;
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: var(--black-60);

  // decorators
  @include breakpoint(small) {
    -webkit-clip-path: none;
    clip-path: none;
  }

  @include breakpoint(large) {
    -webkit-clip-path: none;
    clip-path: none;
  }

  @include breakpoint(xlarge) {
    -webkit-clip-path: none;
    clip-path: none;
  }

  @include breakpoint(100rem) {
    -webkit-clip-path: none;
    clip-path: none;
  }

  &::after {
    content: none;
    display: none;
    position: static;
    z-index: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    background-color: unset;
  }
}

.row.column.process-header__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 1rem;
  z-index: 1;
  @include breakpoint(mediumlarge) {
    flex-direction: row;
    position: relative;
  }
  @include breakpoint(mediumlarge only) {
    padding-left: 2.6rem;
    padding-right: 1rem;
  }
  @include breakpoint(large only) {
    padding-left: 2.6rem;
    padding-right: 1rem;
  }
  @include breakpoint(xlarge) {
    padding: 0;
  }
}
.process-header__info {
  padding: 1rem rem-calc(32px) 1rem 1rem;

  @include breakpoint(medium) {
    padding: 2rem 1.5rem 2rem 1rem;
  }

  @include breakpoint(medium large) {
    padding-left: 2rem;
  }
}

.process-header__hashtag {
  font-weight: var(--font-weight-semibold);

  &::after {
    content: "·";
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.process-header__nav {
  margin-top: auto;
  z-index: 1;
}
