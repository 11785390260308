.inline-filters {
  @include flexgap($global-margin * 2);
  width: 41.7%;
  // Override gap display property
  display: inline-flex;
  justify-content: space-between;
  text-align: left;

  label {
    width: 100%;
    display: flex;
    align-items: baseline;

    @include flexgap($global-margin * 0.5);

    span {
      white-space: nowrap;
      @extend .mini-title;
      width: 25%;
      display: inline-block;
    }
  }

  $dropdown-width: 12rem;

  button[data-toggle] {
    @extend select;
    color: var(--emphasis-med);
    margin: 0;
    min-width: $dropdown-width;
    width: 75%;
    text-align: left;
    align-items: center;
    min-height: 35.58px;
    box-shadow: unset;
    &.hover {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .dropdown-pane {
    padding: 0;
    background-color: $white;
    width: calc(31.275% - 4rem);
    min-width: $dropdown-width;

    li {
      a {
        display: block;
        padding: 1rem;
        color: var(--emphasis-med);
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: var(--primary);
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--grey2);
      }

      &:hover {
        cursor: pointer;
        color: var(--primary);
        background-color: var(--primary-10);
      }
    }

    &.is-open {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
