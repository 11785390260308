#upcoming_meetings,
#participatory-process-group-homepage-highlighted-meetings,
.upcoming-meetings,
.upcoming_meetings {
  padding-top: 4rem;
  padding-bottom: 4rem;
  &--time {
    transform: translateY(2px);
  }
  .card .card__header {
    width: 100%;
  }
  .card .card__header,
  .card .card__text {
    position: relative;
    top: 0;
    padding: 0;
  }
  .card .card__text a {
    @extend .body-5;
    @extend .body-5-link;
  }
  .card .p-s,
  .card .card__content {
    padding: 1rem;
    padding-bottom: 1.25rem !important;
    padding-top: 1.5rem !important;
  }
  .card__link .text-small {
    @extend .body-6;
  }
  .card--list__heading {
    @extend .body-4;
  }
  .heading6,
  h4.card--list__heading.heading6 {
    @extend .body-2;
  }

  .card__link:hover,
  .card__link:focus {
    text-decoration: none !important;
    & .card--list__heading {
      text-decoration: underline !important;
    }
  }
}
