.label--basic{
  background-color: lighten($dark-gray, 60);
}

.label--highlight{
  background-color: var(--secondary);
}

.label--admin{
  background-color: var(--primary);
}

.proposal-status,
.collaborative-draft-status,
.project-status{
  float: left;
  margin-right: .5rem;
}

.proposal-status--small,
.label--small{
  margin: .2rem .2rem .2rem 0;
  padding: .2rem .3rem;
}

.label.light{
  color: $white;
}

.label.muted{
  background: $muted;
  color: $white;
}
