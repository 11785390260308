.conversation{
  .conversation-header{
    @extend .p-m;

    border-bottom: $border;

    h1{
      font-size: $global-font-size;
      font-weight: normal;
    }

    img{
      width: rem-calc(66);
      height: rem-calc(66);
      border-radius: 50%;
    }
  }

  .conversation-chat{
    @include flex;
    @include flex-align(left, top);
    @include flexgap($global-margin);

    @extend .mt-s;

    width: 75%;

    &.conversation-chat--offset{
      @include flex-direction(row-reverse);
      @include flexgap($global-margin, row-reverse);

      margin-left: auto;
    }

    > :first-child{
      flex-shrink: 0;
    }

    > div{
      flex: 1;
    }

    .p-s{
      > p:last-child{
        margin-bottom: 0;
      }
    }

    img{
      width: rem-calc(33px);
      height: rem-calc(33px);
      border-radius: 50%;
    }

    .card{
      margin-bottom: $global-margin * .5;
    }

    .card:last-of-type{
      margin-bottom: 0;
    }
  }

  .conversation-reply{
    @extend .mt-s;

    width: 75%;

    &.conversation-reply--offset{
      margin-left: auto;
    }
  }
}

.conversations{
  .card--list__item{
    align-items: stretch;
  }
}

.conversation-groups{
  .title-action{
    margin-bottom: .8rem;
  }
}

// Conversations in user profile pages to full length
.user-activity{
  .conversation{
    .conversation-reply{
      width: 100%;
    }
  }
}

.disabled-tribute-element{
  color: $muted;

  span.disabled-tribute-element-info{
    font-size: rem-calc(12);
    font-weight: normal;
    font-style: italic;
  }
}

.tribute-container{
  .disabled-tribute-element{
    cursor: auto;
    margin: -5px;
    padding: 5px;
    z-index: 10;
  }
}
