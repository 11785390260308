// Keyframes

@keyframes fadeIn{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

@keyframes fadeOut{
  0%{
    opacity: 1;
  }

  100%{
    opacity: 0;
  }
}
