/* Body/Body-1 */
.body-1 {
  @include typography-body-common;
  &,
  & a {
    font-size: 23px;
  }
  line-height: 120%;

  @include typography-semibold-modifiers;
  @include typography-underline-modifiers;
  @include typography-nocaps-modifiers;
}

/* Body/Body-2 */
.body-2 {
  @include typography-body-common;
  &,
  & a {
    font-size: 20px;
  }
  line-height: 120%;

  @include typography-medium-modifiers;
  @include typography-semibold-modifiers;
  @include typography-underline-modifiers;
  @include typography-nocaps-modifiers;
}

/* Body/Body-3 */
.body-3 {
  @include typography-body-common;
  &,
  & a {
    font-size: 18px;
  }

  line-height: 130%;

  @include typography-medium-modifiers;
  @include typography-underline-modifiers;
  @include typography-caps-modifiers;
  @include typography-nocaps-modifiers;
}

/* Body/Body-4 */
.body-4 {
  @include typography-body-common;
  &,
  & a {
    font-size: 16px;
  }
  line-height: 130% !important;

  @include typography-medium-modifiers;
  @include typography-semibold-modifiers;
  @include typography-bold-modifiers;
  @include typography-caps-modifiers;
  @include typography-nocaps-modifiers;
}

/* Body/Body-5 */
.body-5 {
  @include typography-body-common;
  &,
  & a {
    font-size: 14px;
  }

  line-height: 130%;

  @include typography-medium-modifiers;
  @include typography-semibold-modifiers;
  @include typography-bold-modifiers;
  @include typography-underline-modifiers;
  @include typography-caps-modifiers;
  @include typography-nocaps-modifiers;
  @include typography-italic-modifiers;
}

/* Body/Body-6 */
.body-6 {
  @include typography-body-common;
  &,
  & a {
    font-size: 11px;
  }
  line-height: 130%;

  @include typography-semibold-modifiers;
  @include typography-underline-modifiers;
  @include typography-caps-modifiers;
  @include typography-nocaps-modifiers;
}
