.block-banner{
  background-repeat: no-repeat;
  padding: 1rem;

  @include breakpoint(medium down){
    background-image: none !important;
    padding-bottom: 1rem;

    .card__content{
      padding-bottom: 0;
    }
  }

  @include breakpoint(medium){
    background-position: right -400px center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .card__content{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  @include breakpoint(large){
    padding-left: 4rem;
    padding-right: 4rem;

    .card__content{
      padding-left: 0;
    }
  }

  p:last-child{
    margin-bottom: 0;
  }
}
