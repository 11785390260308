/* Headline/Heading-1 */
.heading-1 {
  @include typography-headline-common;
  &,
  & a {
    font-size: 66px !important;
  }
  @include breakpoint(small only) {
    &,
    & a {
      font-size: 48px !important;
      word-break: break-word;
    }
  }

  @include typography-bold-modifiers;
}

/* Headline/Heading-2 */
.heading-2 {
  @include typography-headline-common;
  font-size: 48px !important;
  @include breakpoint(small only) {
    font-size: 48px !important;
  }
  word-break: break-word;

  @include typography-bold-modifiers;
}

/* Headline/Heading-3 */
.heading-3 {
  @include typography-headline-common;
  &,
  & a {
    font-size: 28px !important;
  }

  @include breakpoint(small only) {
    &,
    & a {
      font-size: 28px !important;
      word-break: break-word;
    }
  }

  @include typography-bold-modifiers;
  @include typography-underline-modifiers;

  @include typography-link-modifiers;
}

/* Headline/Heading-4 */
.heading-4 {
  @include typography-headline-common;
  &,
  & a {
    font-size: 23px !important;
  }
  @include breakpoint(small only) {
    &,
    & a {
      font-size: 23px !important;
      word-break: break-word;
    }
  }
  @include typography-bold-modifiers;
  @include typography-underline-modifiers;

  @include typography-link-modifiers;
}

.heading-5 {
  @include typography-headline-common;
  &,
  & a {
    font-size: 20px !important;
  }
  @include breakpoint(small only) {
    &,
    & a {
      font-size: 20px !important;
      word-break: break-word;
    }
  }
  @include typography-bold-modifiers;
  @include typography-underline-modifiers;

  @include typography-link-modifiers;
}
.heading-6 {
  @include typography-headline-common;
  &,
  & a {
    font-size: 18px !important;
  }
  @include breakpoint(small only) {
    &,
    & a {
      font-size: 18px !important;
      word-break: break-word;
    }
  }
  @include typography-bold-modifiers;
  @include typography-underline-modifiers;

  @include typography-link-modifiers;
}
