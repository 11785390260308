.label-required{
  cursor: help;
  border-bottom: $abbr-underline;
  text-decoration: none;
  padding-right: 0;
  margin-left: 5px;

  > span{
    padding-right: 0;
    margin-right: 0;
  }
}
