.section.columns .card.extra {
  align-items: center;
}

.card.extra {
  box-shadow: none;
  border: 1px solid var(--grey2);
  &.amender-list,
  &.line-stats {
    .definition-data__title {
      margin-bottom: 0;
    }
  }
  &:not(.definition-data, .amender-list, .line-stats) {
    padding: 1.5rem;
  }
  .m-bottom {
    margin-bottom: 0;
  }
  .definition-data__title {
    margin-bottom: 0.25rem;
  }
  .definition-data__number {
    @extend .body-2;
    @extend .body-2-semibold;
  }
  .card__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
