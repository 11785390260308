// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group title-bar
////

/// Background color of a title bar.
/// @type Color
$titlebar-background: var(--grey1);

/// Color of text inside a title bar.
/// @type Color
$titlebar-color: var(--emphasis-med);

/// Padding inside a title bar.
/// @type Length
$titlebar-padding: 0.5rem;

/// Font weight of text inside a title bar.
/// @type Weight
$titlebar-text-font-weight: normal;

/// Color of menu icons inside a title bar.
/// @type Color
$titlebar-icon-color: var(--emphasis-med);

/// Color of menu icons inside a title bar on hover.
/// @type Color
$titlebar-icon-color-hover: var(--grey2);

/// Spacing between the menu icon and text inside a title bar.
/// @type Length
$titlebar-icon-spacing: 0.25rem;

.title-bar {
  padding-right: $titlebar-padding;
  padding-bottom: $titlebar-padding;
  padding-top: rem-calc(24px);
  padding-left: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  @include breakpoint(smallmedium down) {
    padding-left: 0;
  }

  background: $titlebar-background;
  color: $titlebar-color;

  @if $global-flexbox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  } @else {
    @include clearfix;
  }

  .menu-icon {
    margin-#{$global-left}: $titlebar-icon-spacing;
    margin-#{$global-right}: $titlebar-icon-spacing;
  }
}

@if $global-flexbox {
  .title-bar-left,
  .title-bar-right {
    flex: 1 1 0px; // sass-lint:disable-line zero-unit
  }

  .title-bar-right {
    text-align: right;
  }
} @else {
  .title-bar-left {
    float: left;
  }

  .title-bar-right {
    float: right;
    text-align: right;
  }
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: $titlebar-text-font-weight;
}
