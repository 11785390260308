//Variables

$tags-border: 1px solid var(--grey2);

/* Tags */

.tags {
  list-style: none;
  margin: 0;
  padding: 0;
  @extend .body-5;
  color: var(--primary);

  li {
    display: inline-block;
    margin-right: 0.625rem;
    margin-bottom: 0.5rem;
  }

  a {
    display: inline-block;
    border: $tags-border;
    padding: 0.38rem 0.5rem;
    @extend .body-5;
    @extend .body-5-link;

    &:hover,
    &:focus {
      text-decoration: none !important;
      background-color: var(--grey2);
    }
  }
}

.tags:last-child {
  margin-bottom: -0.5rem;
}

.tags--inline {
  display: inline;
}

.tags--list {
  li {
    display: block;
  }
}
