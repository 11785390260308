.collapsible-list {
  &.is-filtered {
    @for $i from 1 through 12 {
      &.show-#{$i} {
        .collapse:nth-of-type(n + #{$i + 1}),
        > div:nth-of-type(n + #{$i + 1}) {
          display: none;
        }
      }
    }

    .collapsible-list__see-more {
      display: inline-block;

      + .text-small {
        padding-top: 0.15rem;
        padding-left: 0.2rem;
      }
    }

    .collapsible-list__see-less {
      display: none;
    }
  }

  .collapsible-list__see-less {
    display: inline-block;
    color: var(--secondary);
  }

  .collapsible-list__see-less:hover {
    color: var(--secondary);
    text-decoration: underline;
  }

  .collapsible-list__see-more {
    display: none;
    color: var(--secondary);
  }

  .collapsible-list__see-more:hover {
    color: var(--secondary);
    text-decoration: underline;
  }

  .card__text--paragraph {
    text-decoration: none;
    color: var(--emphasis-low) !important;
  }

  .card__text--paragraph:hover {
    text-decoration: none;
  }

  > * {
    display: inline-flex;
    margin: 0 0 0.5rem;
  }
}
