// name specific
#profile-tabs{
  // Override foundation
  &.tabs{
    background: transparent;
    border: 0;
    display: flex;
    justify-content: space-around;
    border-bottom: $border;
    margin-bottom: $global-margin;

    .tabs-title{
      &.is-active{
        a{
          background: transparent;
          color: var(--primary);
          font-weight: 600;
          border-bottom: 3px solid var(--primary);
        }
      }

      a{
        color: $anchor-color;
        padding: $global-padding * 3*.25;
        position: relative;

        &:focus,
        &[aria-selected="true"]{
          background: transparent;
          color: var(--primary);
          font-weight: 600;
          border-bottom: 3px solid var(--primary);
        }

        &:hover{
          background: transparent;
          border-bottom: $border;
          border-bottom-width: 3px;
        }

        &::after{
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }
  }
}

// Make the connection between the title and the content
[data-tabs-content="profile-tabs"]{
  border-top: 0;
  background: transparent;
  color: inherit;
  transition: all .5s ease;
}

.responsive-tab-block{
  @media only screen and (max-width: 639px){
    overflow: hidden;
    height: 44px;

    & > ul.tabs{
      flex-direction: column;

      & > li{
        flex: 0 0 auto;

        &.is-active{
          -webkit-box-ordinal-group: -1; // fixes older Safari issues
          order: -1;

          &::before{
            display: block;
            content: " ";
            font-weight: normal;
            float: right;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #f00;
            margin-top: 20px;
          }

          & > a:focus{
            outline: 0 !important;
          }
        }

        & > a{
          border-bottom: 3px solid transparent;
          color: green;

          &:focus{
            outline: 0 !important;
          }
        }
      }
    }

    &.expanded{
      height: auto;

      & > ul.tabs > li.is-active::before{
        transform: rotate(180deg);
      }
    }
  }
}
