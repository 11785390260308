// Organization name

.logo-wrapper span{
  color: white;
  font-weight: 600;
  font-size: 1.4em;
  display: inline-block;
  text-align: left;
  padding-left: 8px;
  line-height: 1;
  position: relative;

  &:hover{
    text-decoration: underline;
  }
}

.logo-wrapper span::before{
  content: "";
  display: block;
  position: absolute;
  border-left: 4px solid white;
  height: 88%;
  top: 6%;
  left: 0;
}
