/* Reveal foundation overwrites */

@include breakpoint(small down) {
  .reveal {
    width: 95%;
    left: 2.5%;
    top: 2rem;
    height: auto;
    min-height: auto;
  }
}

.reveal__header {
  border-bottom: $border;
  margin-bottom: 1rem;

  &.reveal__header--nomargin {
    margin-bottom: 0;
  }
}
.reveal#exit-notification .row:last-child .columns {
  flex-direction: row-reverse;
  display: flex;
  gap: 0.5rem;
}

.reveal#sign-up-newsletter-modal div.row:not(.reveal__header) {
  padding-left: 1rem;
  padding-right: 1rem;
  & > p {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.reveal {
  .wrapper--inner {
    background: transparent !important;
  }
  & > h2 {
    @extend .body-2;
    margin-left: 1rem;
  }
  & > p {
    margin-left: 1rem;
    margin-right: 1.75rem;
  }
  & > .row:last-child {
    padding-top: 1rem;
    margin-top: 2rem;
    border-top: 1px solid var(--grey2);

    &,
    .columns {
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  .button {
    height: 48px;
  }
  &.fingerprint-dialog,
  &#data_picker-modal {
    padding: 1rem;
    .modalTitle {
      margin-bottom: 1rem;
    }
  }
  .new_join_meeting {
    & > .row:last-child {
      text-align: center;
      margin-bottom: 8px;
      margin-top: 2rem;
    }
    .row .help-text {
      margin-top: 1rem;
    }
  }
  &#budget-confirm {
    & > p.text-center {
      text-align: left !important;
    }
    & > .row {
      text-align: center;
      margin-bottom: 8px;
      margin-top: 2rem;
    }
  }
  #login_new_user {
    margin-bottom: 1rem;
  }
  @include breakpoint(medium) {
    .filters__section:first-of-type {
      display: none;
    }
  }
  .calendar-url-description {
    margin-bottom: 0.5rem;
  }

  .calendar_url_input {
    margin-top: 1rem;
  }

  #urlCalendarUrl {
    // Prevent the "not-allowed" cursor in the share input.
    cursor: auto;
  }
}

.reveal__footer {
  border-top: $border;

  .buttons {
    display: flex;
    justify-content: center;
    padding: 10px 0 50px; // bottom padding is needed to avoid hiding buttons on mobiles because of https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser

    @include breakpoint(medium) {
      padding-bottom: 0;
      margin-bottom: -1rem;
    }
  }
}

.reveal__bg {
  background-color: $light-gray-dark;
}
#loginModal .reveal__title {
  text-align: center;
}
.reveal__title {
  margin-top: -6px;
  @extend .body-2;
  outline: none;
}

.reveal__list {
  @extend .list-reset;

  li {
    @extend .p-s;

    display: flex;
  }

  li.selected {
    background-color: rgba($warning, 0.3);
    justify-content: space-between;

    svg {
      color: $success;
      align-self: center;
    }
  }

  li:hover {
    cursor: pointer;
  }

  li:not(:last-child) {
    border-bottom: $border;
  }
}

.reveal__trigger {
  cursor: pointer;
}

.reveal__buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
}

.external-domain-warning {
  .card-section {
    padding: 0;
  }

  .buttons {
    display: flex;
    justify-content: center;

    .button {
      margin: 0;
    }
  }
}

.reveal {
  padding: $reveal-padding 0;
  & > div > * {
    padding-left: $reveal-padding;
    padding-right: $reveal-padding;
  }
  .confirm-content {
    min-height: 60px;
  }
  .reveal__footer.confirm-modal-footer .buttons.button--double {
    flex-direction: row-reverse;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    gap: 1rem;
    .button {
      min-width: 130px;
    }
  }
  .reveal__footer .buttons {
    padding-top: rem-calc(24);
    padding-bottom: rem-calc(24);
    &.button.muted {
      @extend .btn--primary;
    }
  }
}
