/* Callout foundation overwrites */

.callout {
  &,
  &:focus-visible {
    outline: unset;
  }
  &.primary {
    background: $light-gray;
    border-color: var(--grey2);
    border-left: 1px solid var(--grey2);
  }

  &.secondary {
    border-color: var(--grey2);
    border-left: 1px solid var(--grey2);
  }

  &.success {
    border-color: var(--green);
    border-left: 1px solid var(--green);
  }

  &.warning {
    border-color: var(--yellow);
    border-left: 1px solid var(--yellow);
  }

  &.alert {
    border-color: var(--red);
    border-left: 8px solid var(--red);
  }
}

.callout.flash {
  margin-bottom: 1rem;
}
