.card__data-item__label {
  @extend .body-6;
  @extend .body-6-caps;
  color: var(--emphasis-low);
}

.card__data-item__value {
  @extend .body-4;
  color: var(--emphasis-med);
}

.card__data-item {
  display: block;
  @extend .card__data-item__value;

  & > div > strong {
    @extend .card__data-item__label;
    @extend .body-6;
  }
  .heading4 {
    @extend .body-4;
    @extend .body-4-medium;
    letter-spacing: normal;
    color: var(--emphasis-med);
  }

  & > br {
    line-height: 0;
    display: none;
  }
}
