.areachart.metric-chart {
  svg {
    max-height: rem-calc(120);
  }
  .circle,
  .line,
  .area {
    display: none !important;
  }
  .title {
    color: var(--emphasis-low);
    @extend .body-5;
    letter-spacing: normal;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.03em;
    transform: translateY(83px);
  }
  .sum {
    @extend .heading-3;
    @extend .heading-3-bold;
    font-size: 4rem;
    fill: var(--emphasis-med);
  }
}
