#meeting-registration-confirm{
  .help-text{
    border: 1px solid #eee;
    background: #fff;
    padding: .5em;
    overflow-y: scroll;
    margin-bottom: 1em;
    margin-top: .5em;
    height: 20em;
  }
}

.represent-user-group{
  margin-top: .5em;
  margin-bottom: .5em;
}

.public-participation{
  margin-top: .5em;
  margin-bottom: .5em;
}

.user-group-fields{
  margin-bottom: .5em;
}
