.list-request{
  border-top: $border;

  p{
    margin-top: $global-margin;
  }

  > div{
    padding: $global-padding * .5 0;
    border-bottom: $border;

    &:first-of-type{
      border-top: $border;
    }
  }
}
