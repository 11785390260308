.leaflet-container{
  .map-info{
    border: 1px solid var(--primary);

    .leaflet-popup-content-wrapper{
      border-radius: 2px;
    }

    a.button{
      color: #fff;
    }
  }

  .leaflet-popup-tip{
    border: 1px solid var(--primary);
  }
}
