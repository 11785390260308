//impact  project cards, higligted process, blog card, randomly add or
//remove spaces here and there due to strange positioning that may not be due to css

.ql-reset-decidim {
  & > p {
    @extend .body-4;
    margin-bottom: rem-calc(32);

    //Used at least in blog component
    padding: 0 $card-padding;

    //used in projects cards
    &:only-child {
      padding: 0;
      margin: 0;
    }
  }
}

.ql-editor.ql-reset-decidim {
  &:not(.card--post) {
    & > p {
      //Was used at least in help page but stopped working for some reason
      padding: $card-padding 0;

      //used in projects cards
      &:only-child {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.card__content {
  & > .ql-editor.ql-reset-decidim {
    padding-top: 0;
    padding-right: $card-padding;
    padding-bottom: $card-padding;
    padding-left: $card-padding;
  }

  > .card__header {
    margin: 0;
    padding-bottom: 0;
  }
}
