.ql-video{
  max-width: 600px;
  width: 100%;
  height: 450px;
  max-height: 70vh;
  margin: 40px auto;
  display: block;
}

.ql-toolbar .ql-video{
  margin-top: 0;
  margin-bottom: 0;
}
