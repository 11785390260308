.meeting-polls{
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f4f4f4;
    border: 1px solid #e8e8e8;
  }

  &__button{
    @extend .button;
    @extend .button--nomargin;
    @extend .hollow;
    @extend .small;

    background-color: $white;
    font-weight: 600;

    &.is-open{
      background-color: var(--primary);
      color: $white;
    }

    & + &{
      margin-left: $global-margin * .25;
    }
  }

  &__close{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2 * $global-margin;
    cursor: pointer;

    a{
      display: flex;
      align-items: baseline;
    }

    span{
      font-size: 2em;
      margin-left: $global-margin * .5;
      line-height: .5;
    }

    svg{
      margin-left: .2rem;
    }
  }
}
