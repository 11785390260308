// FROM: https://rsms.me/inter/download/

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("fonts/decidim/Inter-Thin.woff2") format("woff2"),
    url("fonts/decidim/Inter-Thin.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: block;
  src: url("fonts/decidim/Inter-ThinItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src: url("fonts/decidim/Inter-ExtraLight.woff2") format("woff2"),
    url("fonts/decidim/Inter-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: block;
  src: url("fonts/decidim/Inter-ExtraLightItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fonts/decidim/Inter-Light.woff2") format("woff2"),
    url("fonts/decidim/Inter-Light.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: block;
  src: url("fonts/decidim/Inter-LightItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fonts/decidim/Inter-Regular.woff2") format("woff2"),
    url("fonts/decidim/Inter-Regular.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: block;
  src: url("fonts/decidim/Inter-Italic.woff2") format("woff2"),
    url("fonts/decidim/Inter-Italic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: url("fonts/decidim/Inter-Medium.woff2") format("woff2"),
    url("fonts/decidim/Inter-Medium.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: block;
  src: url("fonts/decidim/Inter-MediumItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: url("fonts/decidim/Inter-SemiBold.woff2") format("woff2"),
    url("fonts/decidim/Inter-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: block;
  src: url("fonts/decidim/Inter-SemiBoldItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src: url("fonts/decidim/Inter-Bold.woff2") format("woff2"),
    url("fonts/decidim/Inter-Bold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: block;
  src: url("fonts/decidim/Inter-BoldItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: block;
  src: url("fonts/decidim/Inter-ExtraBold.woff2") format("woff2"),
    url("fonts/decidim/Inter-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: block;
  src: url("fonts/decidim/Inter-ExtraBoldItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fonts/decidim/Inter-Black.woff2") format("woff2"),
    url("fonts/decidim/Inter-Black.woff") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: block;
  src: url("fonts/decidim/Inter-BlackItalic.woff2") format("woff2"),
    url("fonts/decidim/Inter-BlackItalic.woff") format("woff");
}

/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("fonts/decidim/Inter-roman.var.woff2") format("woff2");
}
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: block;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("fonts/decidim/Inter-italic.var.woff2") format("woff2");
}
