//Variables
$filters-border: $border;
$filters-padding: 1rem;
$filters-padding-x: 1rem;
$filters-padding-y: 1rem;

// Styles
.filters-wrapper {
  @include breakpoint(medium) {
    display: flex;
  }
}

.filters-controls {
  @include clearfix;

  margin: 1rem 0 2rem;
}

.filters-controls__trigger {
  width: 100%;
  font-weight: 600;
  text-align: left;
  background: $light-gray;
  padding: 0.5rem;
  border: $border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    margin-left: rem-calc(6);
  }
}

.filters {
  display: none;
  min-height: 100%;
  padding: $filters-padding;

  @include breakpoint(medium) {
    display: block;
    padding: 0;
    margin: 0;
  }

  .reveal & {
    display: block;
    border: none;
    padding: 0;
    margin: 0;
  }
}

.js .filters__content {
  display: none;

  &.is-active {
    display: block;
  }

  @include breakpoint(medium) {
    display: block;
  }
}

.filters__section {
  border-bottom: $border;
  padding: $filters-padding-x $filters-padding-y;
  & a, &a:hover {
    text-decoration: none !important;
  }
  &:first-of-type {
    padding-top: $filters-padding-x * 1.5;
  }

  &:last-of-type {
    padding-bottom: $filters-padding-x * 1.5;
    border-bottom: none;
  }

  label {
    line-height: 1.2;
    margin-bottom: 0.4rem;

    &[data-global-checkbox] {
      font-weight: 600;
    }
  }

  .button {
    margin-bottom: 0;
  }

  .section-title {
    color: var(--primary);
    text-decoration: underline;
    margin-right: $global-margin;
  }
}

.filters__subfilters {
  label:last-child {
    margin-bottom: 1rem;
  }
  & > .filters__subfilters {
    padding-left: $filters-padding-x * 0.5;
  }
}

.filters__has-subfilters {
  display: flex;
  justify-content: space-between;
}

.filters__icon-is-open,
.filters__icon-is-closed {
  font-size: 11px;
  padding: 3px 6px;
  &:hover {
    color: var(--primary);
  }
}

.filters__icon-is-open {
  display: none;
}

.filters__is-open {
  .filters__icon-is-open {
    display: inline-block;
  }

  .filters__icon-is-closed {
    display: none;
  }
}

.filters__section--general {
  @extend .filters__section;

  padding: 0;

  &:first-of-type,
  &:last-of-type {
    padding: 0;
  }

  > :first-child {
    padding: $filters-padding-x $filters-padding-y;

    &:hover {
      background: $light-gray;
    }
  }

  &.no-results {
    .section-title {
      color: $muted;
      text-decoration: none;
    }

    > * {
      opacity: 0.25;
    }
  }
}

.filters__info__title {
  margin-bottom: 0;
}

.filters__info__icon {
  margin-left: 0.25rem;
}

.filters__search {
  .input-group {
    margin-bottom: 0;
  }
}

.filters__help {
  position: relative;

  p:last-of-type {
    margin-bottom: 0;
  }

  .skip {
    @include skip-button;
  }
}

.filters__left-legend {
  legend {
    float: left;
    padding-top: 10px;
  }

  .picker-prompt,
  select {
    width: 75%;
    float: right;
  }
}

.filters__section .section-title,
.filters__section--general .section-title {
  color: var(--secondary);
  font-weight: var(--font-weight-medium);
}
a.flex--sbc:hover {
  text-decoration: none;
}
.filters-controls__trigger {
  background: var(--white);
  width: auto;
  padding: 0.4rem 0.7rem;
}
#participatory-space-filters {
  @include breakpoint(medium) {
    .filters-controls__trigger {
      display: none;
    }
  }
}
