.section.documents {
  margin-top: 3rem;
  margin-bottom: 3rem;
  .card {
    box-shadow: none;
    border: 1px solid var(--grey2);
  }
  svg {
    &.icon--cloud-download {
      fill: var(--primary);
    }
    &.icon--caret-right {
      fill: var(--emphasis-med);
    }
  }
  button[type="button"] {
    width: 100%;
    padding: 0;
    padding-top: 0.3rem;
    text-align: left;
  }
  button[aria-expanded="false"] svg {
    transform: rotate(90deg);
  }
  .card--list__data__icon {
    color: var(--primary);
    fill: var(--primary);
  }
  .card__link:hover {
    text-decoration: none;
    .card--list__heading > :first {
      text-decoration: underline;
    }
  }
  .card--list__heading.heading6 {
    @extend .body-4;
    @extend .body-4-link;
    small {
      @extend .body-5;
      color: var(--emphasis-lowest);
    }
  }
  .text-small {
    @extend .body-5;
  }
}
