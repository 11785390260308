.icon-button {
  background: var(--white);
  color: var(--primary);
  border: var(--primary) solid 1px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 29px;
  height: 29px;

  &:hover {
    background: var(--primary);
    color: var(--on-primary) !important;
  }
}

.download-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  gap: 10px;

  width: 28px;
  height: 28px;

  color: var(--emphasis-lowest);
  &:hover {
    color: var(--primary);
  }
}

.register > .Body-4-medium-caps {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  color: var(--primary);

  &:after {
    content: ">";
    font-weight: var(--font-weight-bold);
    font-size: 1.3em;
    vertical-align: middle;
  }
}

.follow-duo-button {
  background: var(--on-primary);
  color: var(--primary) !important;
  border: var(--primary) solid 1px;

  padding: 10px 16px;
  gap: 8px;
  height: 30px;

  &:hover,
  &:focus {
    background: var(--primary) !important;
    color: var(--on-primary) !important;
  }
}

.highlighted-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  gap: 4px;

  width: 218px;
  height: 60px;

  background: var(--primary);
  border-radius: 4px;

  &:hover {
    background: var(--primary-dark);
  }
}

.chip {
  display: flex;
  @extend .body-5;
  color: var(--secondary);
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  gap: 10px;

  height: 22px;

  border: 1px solid var(--grey1);

  &:hover {
    color: var(--secondary);
    background: var(--grey1);
  }
}

.link--go-back {
  @extend .body-4;
  @extend .body-4-link;
  display: inline-flex;
  gap: 4px;
  align-items: center;
}
