.m_accountability-results--home {
  .intro.section {
    margin-bottom: 1rem;
  }
  .intro > .row:first-child > .columns > p {
    color: var(--emphasis-high);
  }
  .input-group-field {
    height: auto;
  }
  hr {
    display: none;
  }
}
.accountability.result-view,
.m_accountability-results,
.m_accountability-versions {
  .card.card--list {
    box-shadow: none;
    margin-bottom: 0.5rem;

    .card--list__heading,
    .card--list__data__number {
      @extend .heading-4;
    }
    .card--list__data__number {
      color: var(--emphasis-low);
    }
    .card__link:hover,
    .card__link:focus {
      text-decoration: none !important;
    }
    .card__link:hover .card--list__heading,
    .card__link:focus .card--list__heading {
      text-decoration: underline;
    }
    .card__link:hover .text-small {
      text-decoration: none !important;
    }
    span.text-small,
    .author-data,
    .author-data .author__name {
      @extend .body-4;
      color: var(--emphasis-low);
    }
    .card--meta span {
      color: var(--emphasis-low);
    }
    .card--meta strong {
      @extend .mini-title;
      color: var(--emphasis-low);
    }
  }
  .scope-filters.section {
    margin-bottom: 2.5rem;
  }
}

.o-results.o-results--show {
  .section:has(.progress-level),
  .section.js-with-progress-level {
    margin-bottom: 1rem;
  }
  .result-view .title {
    @extend .heading-4;
    align-items: center;
  }
  .result-description {
    @extend .body-4;
    color: var(--emphasis-high);
  }
  .result-view .title .icon {
    min-width: 2rem;
    min-height: 2rem;
    color: var(--emphasis-med);
    fill: var(--emphasis-med);
  }
  .accountability.result-view .progress-level {
    background: transparent;
    border-width: 0;
    padding: 0;
    flex-direction: column-reverse;
    display: flex;
    gap: 4px;
    .progress-label {
      gap: 0.5rem;
      justify-content: flex-start;
      align-items: center;
    }
    .progress {
      margin-bottom: 0;
    }
  }
  .result-view .title {
    margin-bottom: 0;
  }
  .result-view .progress-level .progress-label .progress-figure {
    margin-top: 0;
    @extend .body-2;
    @extend .body-2-semibold;
  }
  .result-meta {
    margin-bottom: 0;
    .result-meta--data {
      font-size: 14px !important;
    }
  }
  hr {
    display: none;
  }
  .definition-data__item {
    .author--inline span,
    .text-large {
      @extend .body-4;
      font-weight: normal;
      color: var(--emphasis-med);
    }
  }
}
