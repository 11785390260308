.accountability {
  .categories {
    a:hover {
      text-decoration: underline;
    }

    a:focus {
      outline: $anchor-outline-focus;
    }

    .categories--header {
      border-bottom-width: 0;
      padding-bottom: 1rem;
      margin-bottom: 1.5rem;

      .icon--arrow-bottom {
        fill: var(--emphasis-lowest);
      }

      span {
        @extend .body-3;
        color: var(--emphasis-lowest);
      }
    }

    .categories--group {
      margin-bottom: 2rem;

      @include breakpoint(medium) {
        display: flex;
        margin-bottom: 4rem;
      }

      .category--title {
        @extend .card;
        margin-bottom: 0;
        box-shadow: none;
        min-height: auto;

        @include breakpoint(medium) {
          min-height: auto;
        }
        p {
          margin-bottom: 1rem;
        }
        p > a {
          @extend .heading-4;
          @extend .heading-4-link;
        }

        .progress {
          margin-bottom: 0.3rem;
        }

        .progress-info {
          position: relative;
          margin-bottom: 2rem;

          .progress-figure {
            display: inline-block;
          }

          .category--count {
            position: absolute;
            right: 0;
            top: 0.5rem;
            color: var(--emphasis-lowest);
            @extend .body-4;
          }
        }
      }

      .card__link {
        .category--line {
          @extend .card;
          box-shadow: none;
          background-color: var(--grey2);
          color: var(--primary);
          min-height: 9rem;
          position: relative;

          strong {
            font-weight: normal;
            @extend .body-4;
            @extend .body-4-link;
            color: var(--primary);
          }

          .progress-figure {
            position: absolute;
            bottom: 1rem;
            @extend .heading-4;
          }

          .category--count {
            color: var(--primary);
            position: absolute;
            right: 1rem;
            bottom: 1rem;
            @extend .body-6;
            opacity: 0.8;
          }
        }

        &:hover {
          .category--count {
            color: var(--primary);
          }

          strong {
            color: var(--primary);
          }
        }
      }
    }

    .category--elements.active {
      display: block !important;

      .medium-4:first-child {
        margin-top: 1rem;
      }
    }

    .progress-figure {
      @extend .heading-4;
      color: var(--emphasis-lowest);
    }
  }
}
