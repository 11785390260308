.floating-helper{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.floating-helper__layout{
  position: relative;
}

.floating-helper__trigger{
  position: fixed;
  right: 0;
  display: inline-flex;
  cursor: pointer;
  background-color: $white;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;

  &:active,
  &:focus{
    outline: $anchor-outline-focus;
    outline-color: var(--highlight);
    outline-offset: $anchor-outline-offset;
  }

  @include breakpoint(small only){
    bottom: $global-margin * 2;
  }

  @include breakpoint(medium){
    top: $global-margin * 2;
    position: absolute;
  }
}

.floating-helper__text{
  color: var(--secondary);
  background-color: rgba(var(--secondary-rgb), .1);
  font-size: rem-calc(14);
  font-weight: $global-weight-bold;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  padding: $global-padding * .125 $global-padding * .5 $global-padding * .125 $global-padding;
}

.floating-helper__icon{
  color: $white;
  background-color: var(--secondary);
  padding: $global-padding * .125 $global-padding * .5;
}

.floating-helper__wrapper{
  @include flex;
}

.floating-helper__content{
  background-color: rgba(var(--secondary-rgb), .1);
  border-top: 5px solid var(--secondary);
  margin-bottom: $global-margin * 3;
  padding: $global-padding * 2;
  width: 100%;

  @include breakpoint(medium){
    padding: $global-padding * 3 $global-padding * 2 $global-padding * 2;
  }
}

.floating-helper__content-inner{
  position: relative;

  @include breakpoint(medium){
    @include flexgap($global-margin * 3);
  }
}

.floating-helper__icon-big{
  border-radius: 50%;
  background-color: rgba(var(--secondary-rgb), .1);
  flex-shrink: 0;
  color: var(--secondary);

  @include flex;
  @include flex-align($x: center, $y: middle);
  @include square(70px);
}

.floating-helper__bottom{
  text-align: right;

  @include breakpoint(medium){
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.floating-helper__link{
  font-weight: $global-weight-bold;
  text-decoration: underline;
}

.floating-helper__content-close{
  background-color: var(--secondary);
  color: $white;
  flex-shrink: 0;
  cursor: pointer;

  @include flex;
  @include flex-align($x: center, $y: middle);
  @include square(1.5rem);

  &:active,
  &:focus{
    color: $white;
  }
}
