.m_notifications {
  min-height: 60vh;
  .card.card--widget .card_data {
    padding-left: 0;
    .card-data__item {
      &:first-child {
        border-top-left-radius: $global-radius;
        border-bottom-left-radius: $global-radius;
      }
      &.card-data__item--expand.absolutes > div:last-child {
        position: absolute;
        top: 2.3rem;
        right: 0;
      }
    }
    .card-data__item:hover {
      text-decoration: none;
    }
  }
}
