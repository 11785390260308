.loading-spinner{
  margin: 25px !important;
  height: 25px;
  text-align: center;

  &::before{
    @include spinner(25px, $medium-gray, var(--primary), 800ms);

    content: "";
    vertical-align: middle;
  }
}

.spinner-container{
  position: relative;
  cursor: wait;

  &::before{
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, .8);
    z-index: 1;
  }

  &::after{
    -webkit-animation: animation-spin 800ms infinite linear;
    animation: animation-spin 800ms infinite linear;
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 3px solid #e8e8e8;
    border-right-color: var(--primary);
    display: inline-block;
    position: absolute;
    content: "";
    vertical-align: middle;
    z-index: 1;
    top: 47%;
    left: 47%;
  }
}
