.list-docs{
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-docs__icon{
  color: $muted;
  margin-right: 3px;
}

.list-check{
  list-style: none;
  margin: 0;
  padding: 0;

  > li{
    margin-bottom: 1rem;
  }
}

.list-check__icon{
  vertical-align: -.05rem;
  margin-right: .2rem;

  .list-check__ok &{
    color: $success;
  }

  .list-check__ko &{
    color: $alert;
  }
}
