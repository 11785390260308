// Proposal, action, debate & meeting view

//Styles

.view-header{
  margin-bottom: 2rem;
}

.support-wrapper-md{
  @include breakpoint(medium down){
    margin: 1rem 0;
  }
}

.view-side{
  @include breakpoint(medium only){
    width: 50%;
    margin: 0 auto 2rem;
    float: none;
  }
}
