.flash.callout {
  margin-bottom: 1rem;
}
.flash.callout,
.callout.secondary,
.callout {
  border-radius: 8px;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--grey2);
  background: var(--white) !important;
  padding: 2rem;
  padding-top: 1.5rem;
  max-width: $global-width;
  margin: 0 auto;
  margin-bottom: 1rem;
  @extend .body-4;
  color: var(--emphasis-high);
  .columns,
  .columns.medium-8,
  .columns.large-9 {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .heading5,
  .heading3 {
    @extend .body-2;
    color: var(--emphasis-high);
    margin-bottom: 1rem;
  }
  &.success {
    border-color: var(--green);
  }
  &.warning {
    border-color: var(--yellow);
  }
  &.alert {
    border-color: var(--red);
  }
}
