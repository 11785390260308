/* Typography - Foundation overrides */

button,
input {
  font-family: inherit;
}

a {
  @extend .body-4;
  @extend .body-4-link;
}

button {
  &:focus {
    outline: 0;
  }
}
.heading1 {
  @extend .heading-1;
}

.subheading1 {
  @extend .heading-3;
}

.heading2 {
  @extend .heading-2;
}

.heading3 {
  @extend .heading-3;
}

.heading4 {
  @extend .heading-4;
}

//not supposed to exist
.heading5,
.heading6,
.heading-small {
  @extend .body-1;
}

hr {
  width: 50%;

  &.reset {
    width: 100%;
    //TODO: Hide the unnecessary divider under assemblies title in assemblies page
    border: 0;
  }
}

/* New typographic styles */

.section-heading {
  @extend .body-2;
  @extend .body-2-semibold;
  color: var(--emphasis-med);
  letter-spacing: inherit;
  text-transform: none;
  max-width: $global-width;
  margin-right: auto;
  &::before {
    content: none;
    display: none;
    width: 0;
    height: 0;
    background-color: transparent;
    margin-right: 0;
    margin-bottom: 0;
    vertical-align: inherit;
  }

  &.collapse {
    margin: 0;
  }

  span {
    font-weight: normal;
  }
}

.mini-title {
  color: var(--emphasis-lowest);
  font-size: 14px;
  letter-spacing: normal;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0;
  letter-spacing: 0.03em;

  &__strong {
    color: var(--emphasis-low);
    @extend .body-1;
    @extend .body-1-semibold;
  }

  &__strong--highlight {
    color: var(--emphasis-med);
    @extend .body-1;
  }
}

.data-title {
  &__over {
    @extend .body-5;
  }

  &__main {
    @extend .body-5;
  }

  &__sub {
    @extend .body-6;
    @extend .body-6-caps;
  }
}

.page-title-wrapper {
  text-align: left;
}

.page-title {
  margin-bottom: 3rem;

  a:hover {
    text-decoration: underline;
  }
}

.text-highlight {
  margin-bottom: 0;
  color: $white;
  text-shadow: none;

  &.heading1 {
    @extend .heading-1-bold;
  }

  > a {
    color: var(--primary);

    &:hover {
      color: var(--primary-10);
    }
  }
}

// text status-color
$map: map-merge(
  $foundation-palette,
  (
    muted: $muted,
  )
);

.text-primary {
  color: var(--primary);
}
.bg-primary {
  background-color: var(--primary-10);
}
.text-secondary {
  color: var(--secondary);
}
.bg-secondary {
  background-color: var(--secondary-10);
}
.text-alert {
  color: var(--red);
}
.bg-alert {
  background-color: var(--red);
}
.text-warning {
  color: var(--yellow);
}
.bg-warning {
  background-color: var(--yellow);
}
.text-success {
  color: var(--green);
}
.bg-success {
  background-color: var(--green);
}

.text-large {
  font-size: 20px;
}

.text-medium {
  @extend .body-5;
}

.text-small {
  font-size: 16px;
}

.text-sm {
  font-size: 16px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-muted {
  color: var(--emphasis-lowest);
}

.text-ellipsis {
  @include ellipsis();
}

.text-compact {
  line-height: 1.2;
}

.word-wrapper {
  display: inline-block;
}

/* HTML inline text elements. Modifiers color text */
a,
abbr,
acronym,
b,
bdo,
big,
br,
button,
cite,
code,
dfn,
em,
i,
img,
input,
kbd,
label,
map,
object,
q,
samp,
script,
select,
small,
span,
strong,
sub,
sup,
textarea,
time,
tt,
var {
  @include modifiers(
    color,
    (
      muted: var(--emphasis-lowest),
    )
  );
}

blockquote {
  border-left-color: var(--emphasis-lowest);
  padding-left: 0.5rem;
}

ul.no-bullet-indented,
ol.no-bullet-indented {
  list-style-type: none;
}

.external-link-indicator {
  display: none;
}
