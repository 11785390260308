.lines-breadcrumb{
  margin-bottom: 2rem;

  .breadcrumb--separator{
    color: darken($light-gray, 40);
    padding: 0 .25rem;
  }

  div{
    display: inline-block;
  }

  a{
    font-weight: 600;
    color: $dark-gray;

    &:hover{
      text-decoration: underline;
    }

    &:focus{
      outline: 2px solid $secondary;
    }
  }

  ul{
    list-style: none;
    margin-left: 0;
  }

  .percentage{
    color: darken($light-gray, 40);
    font-weight: 400;
    padding-left: .15rem;
    display: inline-block;
  }
}
