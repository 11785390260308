.m_pages {
  .page-title-wrapper {
    .heading1 {
      @extend .heading-3;
      margin-bottom: rem-calc(22);
      text-align: center;
    }
    .section-heading {
      @extend .body-2;
      @extend .body-2-nocaps;
      @extend .body-2-semibold;
      text-transform: none;
    }
    .intro--text.subheading1 {
      @extend .heading-4;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      padding-bottom: rem-calc(64);
    }
    .card,
    .card p {
      @extend .body-4;
    }
  }

  &.m_pages--tabbed .m_pages.m_pages--standalone > .column {
    max-width: calc(85% - #{$global-margin});
    box-shadow: 1px 1px 1px var(--black-6);
    border-radius: $global-radius;
    padding: 0;
  }

  &.m_pages--standalone > .row.column {
    padding-bottom: 2rem;

    .callout {
      margin: 0 1.5rem !important;
    }

    & > .row.column {
      background: var(--white);
    }
    & > .columns {
      margin: 0 !important;
      padding: 1.5rem;
      background: var(--white);
      .card {
        box-shadow: none;
        margin-top: 2rem;
        margin-bottom: 1rem;
        .card__header {
          margin-left: 0;

          width: 100%;
          .card__title {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .page-title-wrapper {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      background: var(--white);
      text-align: left;
      border-radius: 0;
      border-top-right-radius: $global-radius;
      border-top-left-radius: $global-radius;
      padding-bottom: rem-calc(64);

      .page-title {
        padding: 1.5rem 1.5rem 0 1.5rem;
        margin-bottom: 0;
      }
      .heading1 {
        text-align: left;
      }
      & + .columns {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
        padding: 0;

        .card__content {
          padding-top: 0;
          padding-left: 1.5rem;
        }
        .card {
          min-height: 42vh;
          border-radius: 0;
          border-bottom-right-radius: $global-radius;
          border-bottom-left-radius: $global-radius;
          box-shadow: unset;
          margin-bottom: 0;
        }
      }
    }
  }
  &.m_pages.m_pages--tabbed .main-container {
    background-color: transparent;
  }
  &.m_pages.m_pages--tabbed .main-container__content {
    min-height: 42vh;
    background: var(--white);
    border-radius: $global-radius;
  }
  .tabs-title {
    border: 0;
  }
}
