.lines-breadcrumb {
  margin-bottom: 2rem;

  .breadcrumb--separator {
    color: var(--emphasis-lowest);
    padding: 0 0.25rem;
  }

  div {
    display: inline-block;
  }

  a {
    font-weight: normal;
    @extend .body-4;
    @extend .body-4-link;
    color: var(--emphasis-low);
    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--primary);
      outline: unset;
      outline-offset: 0;
    }
  }

  ul {
    list-style: none;
    margin-left: 0;
  }

  .percentage {
    color: var(--emphasis-lowest);
    @extend .body-4;
    padding-left: 0.15rem;
    display: inline-block;
  }
}
