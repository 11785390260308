.btn--nav {
  flex-direction: column;
  background: var(--white);
  color: var(--emphasis-lowest);
  padding: 16px 29px;

  border-radius: 8px;

  &:hover,
  &:focus {
    background: var(--grey);
  }

  &active {
    flex-direction: column;
    background: var(--primary-10);
    color: var(--primary);

    border-radius: 8px;

    &:hover,
    &:focus {
      background: var(--primary-10);
    }
  }
}

.btn--nav-more {
  i:first-child {
    margin-left: 0.3rem;
  }
  display: inline-flex;
  flex-direction: row;
  align-items: center !important;
  background: var(--primary-10);
  color: var(--primary);
  height: 24px;

  padding: 4px 16px;
  border-radius: 8px;

  &:hover,
  &:focus {
    background: var(--primary-10);
    color: var(--primary);
  }

  &--active {
    background: var(--on-primary);
    color: var(--primary);

    &:hover,
    &:focus {
      background: var(--on-primary);
      color: var(--primary);
    }
  }
}

.btn--sub-nav {
  flex-direction: column;
  background: var(--white);
  color: var(--primary);
  padding: 16.5px 10px;

  border-radius: 8px;

  &:hover,
  &:focus {
    background: var(--primary-10);
  }

  &:after {
    display: block;
    position: absolute;
    top: -4px;
    content: " ";
    height: 4px;
    width: 100%;

    background: var(--primary);
  }
}
