.accountability{
  .intro{
    ul{
      margin-bottom: 1rem;
    }

    .progress-level{
      background: $white;
      padding: 1.5rem 1.75rem;

      p{
        font-weight: 600;
        margin-bottom: .25rem;
        display: inline-block;
        margin-right: .5rem;
        font-size: 1.25rem;
      }

      .icon{
        background: lighten($dark-gray, 50);
        border-radius: 50%;
        fill: white;
        padding: .15rem;
      }

      .progress{
        max-width: 85%;
        margin-bottom: 0;
      }

      .progress-figure{
        font-size: 2.5rem;
        font-weight: 300;
        color: lighten($dark-gray, 50);

        @include breakpoint(medium){
          font-size: 4rem;
        }
      }
    }
  }

  .scope-filters{
    div{
      color: lighten($dark-gray, 50);
      margin-bottom: .15rem;
    }

    span{
      margin-right: .5rem;
    }

    ul{
      display: inline;
    }

    li.active{
      background-color: $medium-gray;
    }
  }

  .description{
    margin-bottom: 1rem;
  }

  .title-action{
    margin-bottom: .5rem;
  }
}

.result-view{
  .title{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    flex-direction: column;

    .icon{
      fill: $secondary;
      min-width: 1.5rem;
      min-height: 1.5rem;
      margin-bottom: .5rem;

      @include breakpoint(medium){
        margin-right: .75rem;
        margin-top: .5rem;
      }
    }

    @include breakpoint(medium){
      flex-direction: row;
    }
  }

  .progress-level{
    background: $white;
    border-radius: 4px;
    padding: 1.5rem;
    border: 1px solid $medium-gray;

    .progress-label{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: .5rem;

      .progress-text{
        color: lighten($dark-gray, 30);
      }

      .progress-figure{
        line-height: 1;
        font-size: 2.25rem;
        font-weight: 600;
      }
    }
  }

  .result-description div{
    margin-bottom: 1rem;
  }

  .result-meta{
    margin-bottom: .5rem;
    font-size: 1.25rem;

    .result-meta--label{
      font-weight: 600;
      color: lighten($dark-gray, 30);
    }

    .result-meta--data{
      font-size: 1.15rem;
    }
  }

  hr{
    width: 100%;
    margin: 0 0 3rem;
  }

  .timeline{
    .timeline__info{
      background-color: transparent;
      border: none;
    }
  }
}
