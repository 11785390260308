// @Component
[data-toggle] {
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  span {
    margin-right: 0;
  }
}
.label-required[data-toggle] {
  margin-left: 4px;
  margin-bottom: 0;
}
