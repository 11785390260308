.close-button {
  @extend .body-4;
  @extend .body-4-link;
  font-size: 1em;
  color: var(--emphasis-med) !important;
  &:hover,
  &:focus {
    text-decoration: none !important;
  }
  transform: scale(1.5) translateY(1px);
}

a.button,
button.button,
[type="submit"].button,
.button {
  @extend .btn;
  @extend .btn--primary;
  position: relative;

  &.light,
  &.secondary.light,
  &.button--shadow,
  &.secondary.button--shadow,
  &.hollow,
  &.secondary.hollow,
  &.light:not(.hollow):not(.meeting-polls__button).secondary,
  &.light.meeting-polls__button:not(.hollow):not(.meeting-polls__button).secondary {
    @extend .btn--secondary;
  }

  &.expanded,
  .expanded {
    display: inline-flex;
    width: auto;
    margin-right: 0;
    margin-left: 0;
  }

  &.small,
  .small {
    @extend .btn;
    @extend .btn--small;
    @extend .body-4;
    &.compact {
      @extend .btn;
      @extend .btn--small;
      @extend .body-4;
      letter-spacing: normal;
      white-space: initial;
    }
  }

  &.tiny,
  .tiny,
  &.compact,
  .compact {
    @extend .btn--small;
    letter-spacing: normal;
  }

  &.button--right {
    float: right;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.focus,
  &:focus,
  &.hover,
  &:hover,
  &.active,
  &:active {
    box-shadow: initial;
    filter: none;
  }
  &.disabled,
  &[disabled] {
    &,
    &.primary,
    &.secondary {
      @extend .btn--disabled;
    }
  }

  &.primary {
    @extend .btn--primary;
  }
  &.secondary {
    @extend .btn--secondary;
    &[disabled] {
      border-color: var(--emphasis-lowest);
    }
  }
  &.success {
    @extend .btn--primary;
  }
  &.alert {
    @extend .btn--danger;
  }
  &.warning {
    @extend .btn--warning;
  }
  &.clear {
    &,
    &.secondary,
    &.success,
    &.alert,
    &.warning {
      background-color: transparent !important;
      border: unset;
      @extend .body-4;
      @extend .body-4-nocaps;
      @extend .body-4-link;

      &.hover,
      &:hover,
      &.focus,
      &:focus {
        background-color: transparent !important;
      }
    }

    &.secondary {
      color: var(--secondary);
    }

    &.success {
      color: var(--green);
    }

    &.alert {
      color: var(--red);
    }

    &.warning {
      color: var(--yellow);
    }
  }
}

.progress-meter {
  background-color: var(--secondary);
}

// Button groups
.button-group--collapse {
  text-align: left;
  margin-bottom: initial;
  display: inline-flex;

  > :last-child {
    flex: 1;
  }

  > :first-child,
  > :first-child * {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :last-child,
  > :last-child * {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > :not(:first-child):not(:last-child),
  > :not(:first-child):not(:last-child) * {
    border-radius: 0;
  }

  &.no-gaps {
    .button {
      margin-right: initial;
    }
  }
}

// buttons layout
.buttons__row {
  @include flexgap(0);
}

.buttons__column {
  @include flexgap(0, column);
}

/* Extra buttons styles */

.button--shadow {
  $shadows: (
    primary: initial,
    secondary: initial,
    success: initial,
    warning: initial,
    alert: initial,
    muted: initial,
  );

  @include modifiers(background-color, $shadows) {
    color: var(--white);
  }
}

.button--icon {
  display: inline-flex;
  gap: rem-calc(4);
  position: static;

  .icon-wrap {
    position: static;
    left: initial;
    top: initial;
    padding: initial;
    border-right: initial;
    height: rem-calc(24);
    min-width: initial;
    white-space: initial;
    padding-right: initial;
    transform: translateY(-2px);
    .icon {
      margin: initial;
      vertical-align: initial;
    }
  }

  .text-wrap {
    display: inline-flex;
    margin-left: initial;
  }

  .icon {
    margin: initial;
  }

  &.hollow {
    @extend .btn--secondary;
    .icon-wrap {
      border-right: initial;
    }

    &,
    &.secondary {
      .icon-wrap {
        border-right: initial;
      }
      &:hover,
      &:focus {
        color: var(--primary);
        background: var(--primary-10);
      }
    }
  }

  &.small {
    .icon-wrap {
      padding: 4px;
      min-width: 12px;
    }

    .icon {
      width: 12px;
      height: 12px;
    }
  }
}

.button--nomargin {
  margin: 0;
}

.button--frosted {
  background-color: initial;
  color: initial;
}

.button--muted {
  @extend .btn--disabled;
}

.button--sc {
  font-weight: var(--font-weight-medium);
  letter-spacing: normal;
  &.hollow {
    font-weight: var(--font-weight-medium);
    letter-spacing: normal;
  }
  &.button {
    font-weight: var(--font-weight-medium);
    @extend .body-4-nocaps;
  }
}

//highligthted process button
.large-6.large-offset-6.columns {
  & > .button.expanded.button--sc {
    @extend .highlighted-button;
    line-height: 0.75rem;
    &:nth-child(2) {
      @extend .body-4;
      @extend .body-4-bold;
      @extend .body-4-caps;
    }
    & > .button__info {
      @extend .body-6;
    }
  }
}
.o-proposals .view-side .card__content {
  padding: 1rem;
  gap: 0rem;
  align-items: center;
  justify-content: center;
  & .button--vote-button {
    margin: 0.5rem auto 2rem auto;
  }
}
.view-side .card__content {
  .buttons__row {
    width: 100%;
  }
  .button-group {
    div.button.secondary,
    div.button.primary,
    div.button {
      &:hover,
      &:focus {
        background: transparent;
      }
    }

    & > :first-child,
    & > :first-child * {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > :last-child,
    & > :last-child * {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    & > .button:first-child {
      padding: rem-calc(12px) rem-calc(16);
      padding-right: rem-calc(8);
    }

    & > :last-child .button {
      padding: rem-calc(12px) rem-calc(16);
      padding-left: rem-calc(8);
    }
  }
}
.button__info {
  @extend .body-6;
  max-width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  letter-spacing: 0.01em;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 0;
}

button[type="submit"] {
  &.link > svg,
  &.button > svg {
    display: inline-block !important;
  }
}

.link {
  @extend .body-4;
  @extend .body-4-medium;
  @extend .body-4-link;
}

.link-alt {
  cursor: initial;
  color: initial;
  font-weight: initial;

  &:hover {
    color: initial;
    text-decoration: initial;
    text-decoration-skip-ink: initial;
  }
}

.muted-link {
  font-weight: initial;
  color: initial;

  &:hover {
    color: initial;
    text-decoration: initial;
  }
}

.show-more {
  text-align: initial;
  margin-bottom: initial;
}

/* Social buttons */

.button--social {
  @extend .btn;
  @extend .btn--small;
  display: inline-flex;
  min-width: initial;
  flex-grow: 1;

  &::after {
    content: "";
    clear: both;
  }
}

.button--social__icon,
.button--social__text {
  display: inline-flex;
  padding: initial;
}

.button--social__icon {
  display: inline-flex;
  position: static;
  top: initial;
  bottom: initial;
  vertical-align: initial;
  padding: initial;
  border-right: initial;
  margin-right: initial;
  min-width: initial;
  text-align: initial;
}

.button--social__text {
  margin-left: initial;
}

.button--social--mini {
  padding: initial;
  min-width: initial;

  .button--social__icon {
    border: initial;
    padding: initial;
    margin-right: initial;
  }
}

.button--twitter {
  background-color: initial;

  &:hover {
    background-color: initial;
    filter: initial;
  }
}

.button--facebook {
  background-color: initial;

  &:hover {
    background-color: initial;
    filter: initial;
  }
}

.button--google {
  background-color: initial;
  font-family: initial;

  &:hover {
    background-color: initial;
    filter: initial;
  }

  .button--social__icon {
    padding: initial;

    svg.icon {
      fill: var(--secondary);
      width: rem-calc(12);
      height: rem-calc(12);
      margin-top: 0;

      /*
         * This is a hack to comply with google guidelines
         * https://developers.google.com/identity/branding-guidelines
         * Currently the icons.svg file have the wrong google icon, until that's fixed
         * google icon can be specified in secrets.yml with icon_path: decidim/brands/google.svg
         * however to facilitate upgrades this provides a fallback
         */
      max-width: rem-calc(12);
      max-height: rem-calc(12);

      &.icon--google {
        > use {
          display: block;
        }

        background-image: none;
      }
    }
  }

  &.button--social--mini {
    .button--social__icon {
      font-size: initial;
      min-width: initial;
      padding: initial;

      svg.icon {
        margin: initial;
      }
    }
  }
}
.button svg {
  overflow: visible;
}
.button-group--collapse > :first-child {
  border-right-width: 0 !important;
  margin-right: 0;
  padding-left: 0.5rem;
}
.button-group--collapse > :last-child {
  padding-right: 0.5rem;
}
