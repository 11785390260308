.budget-list {
  margin-bottom: 2rem;

  &__item {
    padding: 0;
    background: $card-bg;
    border: $border;
    border-bottom: 0;
    overflow: hidden;
    display: flex;

    &:first-child {
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
    }
    &:not(:last-child) {
      border-bottom: 1px solid var(--grey2);
    }

    &-cell {
      display: grid;
      grid-template-columns: 1fr 40px;

      .budget-list__data {
        grid-column: span 2;
        background: none;
      }

      @include breakpoint(medium) {
        grid-template-columns: 1fr 40px 15em;
        grid-template-rows: 1fr;

        .budget-list__data {
          background: none;
          grid-column: 3;
        }
      }
    }
  }

  &__image {
    overflow: hidden;
    width: 3.5em;
    height: 3.5rem;
    flex-shrink: 0;
    margin-top: $card-padding-small * 0.7;
    margin-bottom: $card-padding-small * 0.7;
    margin-left: $card-padding-small * 0.5;

    @include breakpoint(medium) {
      width: 6rem;
      height: 4rem;
      flex-shrink: 0;
      margin: $card-padding;
      margin-right: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    padding: $card-padding-small * 0.5;
    flex-grow: 1;

    @include breakpoint(medium) {
      padding: $card-padding;
      display: flex;
      align-items: center;

      &.flex-horizontal {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .card__text--status {
      display: block;
    }
  }

  &__data {
    flex-shrink: 0;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 85%;
    line-height: 1;
    color: var(--grey2);
    padding: $card-padding-small * 0.5;
    background: $card-secondary-bg;

    @include breakpoint(medium) {
      min-width: 7rem;
      flex-direction: row;
      justify-content: flex-end;
      flex-basis: 14rem;
      padding: 1rem $card-padding;
    }

    .loading-spinner {
      margin: 0 !important;
      position: relative;

      &::before {
        position: absolute;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__data__icon {
    .icon {
      width: 20px;
      height: 20px;
      fill: var(--grey2);
    }

    &:hover {
      .icon {
        fill: $anchor-color;
      }
    }
  }

  &__data__number {
    @extend .card-data__item__number;

    font-size: 1.5rem;
    line-height: 1.2;
  }

  &__data__votes {
    @extend .card-data__item__number;

    @include breakpoint(medium) {
      flex-grow: 1;
      font-size: 1.2rem;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0;
      text-align: cemter;
    }
  }

  &__data--added {
    background-color: tint($success, 80%);
  }

  &__title {
    font-size: $global-font-size;
  }

  &__number {
    @include breakpoint(medium down) {
      font-size: 1rem;
    }

    @include breakpoint(medium) {
      flex-grow: 1;
      font-size: 1.2rem;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0;
      text-align: right;
    }
  }

  &__action {
    margin-bottom: 0;
    border-radius: 50%;
    align-self: center;
    flex-shrink: 0;
    width: 2em;
    min-width: 2em;
    height: 2em;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;

    &--disabled {
      pointer-events: none;
    }
  }
}
