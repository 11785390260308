.docs__container {
  h6 {
    cursor: pointer;
  }

  svg {
    color: var(--emphasis-med);
  }

  .docs__content {
    border-left: 1px solid var(--grey2);
    padding-top: 0.3rem;
    padding-left: 1rem;
    margin-left: 0.25rem;
  }
}
