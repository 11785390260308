.chart-tooltip{
  @extend .card;

  position: absolute;
  transform: translate(-50%, -119%);
  box-shadow: 0 0 20px 0 rgba($black, .2);
  overflow: visible;

  .tooltip-content{
    @extend .text-medium;
    @extend .p-xs;

    position: relative;

    &::after,
    &::before{
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after{
      border-color: rgba($white, 0);
      border-top-color: $white;
      border-width: 10px;
      margin-left: -10px;
      top: calc(100% - 1px); // webkit
    }

    &::before{
      border-color: rgba($medium-gray, 0);
      border-top-color: $medium-gray;
      border-width: 11px;
      margin-left: -11px;
    }
  }
}
