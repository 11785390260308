.m_meetings-meetings {
  &.m_meetings-meetings--new,
  &.m_meetings-meetings--edit {
    .meetings_form {
      padding: 1rem;
    }

    .form-error {
      transform: translateY(-7px);
    }
    .data-picker .picker-values:empty {
      display: none;
    }

    label,
    .field > label {
      margin-bottom: 0;
    }
    & > .field,
    & > .row.column {
      margin-bottom: 0.5rem;
    }
    .actions {
      margin-top: 1rem;
    }
    .picker-values {
      padding: 0;
      margin-top: 0 !important;
    }
  }

  &.m_meetings-meetings--show {
    .view-side{
      justify-content: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .agenda-section {
      .card__content.scroll {
        max-height: unset;
        overflow-y: auto;
        padding: 1rem;
        p:empty {
          display:none;
        }
        hr.reset.m-none {
          display: none;
        }
        .agenda-item--title.heading5:not(:first-child) {
          @extend .heading-3;
          margin-top: 2rem;
        }
        h6.heading6 {
          @extend .body-2;
          text-transform: lowercase;
          &:not(:first-child) {
            margin-top: 1rem;
          }
        }
      }
    }
    .columns.section .definition-data {
      margin-top: 1rem;
    }
    @include breakpoint(medium) {
      .extra__date-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        & > .extra__date {
          min-width: 100%;
        }
        & > svg {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }
    }
    .author-data__extra a {
      text-wrap: no-wrap;
    }
    .author-data__extra a:first-of-type {
      display: none;
    }
    .section .card--list__text .author-data {
      width: 100%;
      .author-data__main {
        width: 100%;
        display: block;
      }
      .author.author--inline,
      .author.author--inline a {
        width: 100%;
      }
    }
    .section .card--list__text .icon--actions.icon {
      color: var(--green);
    }

    @include breakpoint(small only) {
      .follow-button {
        margin-bottom: 1rem;
        text-wrap: nowrap;
      }
    }

    .share-link {
      margin-bottom: 0.5rem;
    }
    .address__info {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .address__info .address__icon {
      margin-right: 0.5rem;
      .icon--meetings.icon {
        width: 26px;
        height: 26px;
      }
    }
    .author-data__extra > span {
      display: none;
    }
    .author-data__extra > button {
      @extend .body-4;
      @extend .body-4-link;
      padding-left: 1rem;
      .icon--small {
        width: 16px;
        height: 16px;
      }
    }

    .card.extra .card__content {
      text-align: center;
      align-items: center;
      justify-content: center;
      .registration_code {
        strong {
          display: inline-block;
          @extend .body-2;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
          color: var(--emphasis-high);
        }

        .text-small {
          @extend .card__data-item__label;
        }
      }
    }
  }
}
.m_meetings-meetings.m_meetings-meetings--index,
.o-meetings--index {
  .dropdown.menu {
    transform: translateY(2px);
    z-index: 9999;
  }
  .card {
    background: white;
    border-color: var(--grey2);
    border-width: 1px;
    border-style: solid;
    padding-top: 0rem;
    padding-bottom: 0.5rem;
  }
  .button.calendar {
    @extend .btn;
    @extend .btn--primary;
    @extend .btn--small;
    margin-bottom: 1rem;
    margin-top: 1rem;
    align-items: center;
  }
  @include breakpoint(small only) {
    .button.calendar {
      float: none !important;
    }
    .title-action {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      margin-bottom: 0;
    }
    .results-per-page {
      margin-bottom: 0;
    }
    .filters-controls {
      margin-bottom: 0.5rem;
    }
  }
  @include filters-area;
  .columns .row:last-child {
    .text-right {
      text-align: left !important;
      padding-left: 1rem;
    }
  }
}
.card--meeting {
  .m_proposals-proposals--show & {
    padding: 1.5rem 1rem 1rem;
    .card__datetime {
      border-left: 0 !important;
      padding-left: 0;
      background-color: transparent;
      margin-top: -0.8em;
      margin-bottom: 0.5rem;
      &,
      .card__datetime__date,
      .card__datetime__month,
      .card__datetime__time {
        @extend .body-5;
      }
    }
  }
  .card__header {
    margin-top: 1.5rem;
    width: 100%;
  }
  .card__text:not(:last-child) {
    margin-bottom: 0;
  }
  .follow_status {
    flex: 0.66;
  }
  .card__button {
    flex: 0;
  }
  .card-data__item--multiple > div {
    @extend .mini-title;
    margin-bottom: 0 !important;
    &,
    & strong {
      @extend .card__data-item__label;
      @extend .body-6;
    }
  }
}

.o-registrations {
  .o_participatory_process > .row:first-child > .section-heading {
    width: 50%;
    margin-left: auto;
    margin-top: auto;
    padding-left: 0.5rem;
  }
}

.o-meeting_closes {
  .edit_close_meeting {
    padding: 1rem;
    #close_meeting_proposals .picker-values{
      padding-left: 0;
    display: flex;
    gap: 0.2rem;
    padding-bottom: 0.2rem;
    }
    .form-general-submit {
      margin-top: 1rem;
    }
  }
}