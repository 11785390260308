.docs__container{
  h6{
    cursor: pointer;
  }

  svg{
    color: $medium-gray;
  }

  .docs__content{
    border-left: $border;
    padding-left: 1rem;
    margin-left: .25rem;
  }
}
