.badges .card--badge {
  padding-bottom: 0.65rem;
  .card__header {
    padding-bottom: 1rem;
  }
  .card__footer {
    padding-top: 1rem;
  }
  .card__text {
    &:first-of-type {
      margin-bottom: 0;
    }
    padding: 0 0.5rem;
    @extend .body-4;
    color: var(--emphasis-low);
    strong {
      @extend .body-4-bold;
      color: var(--emphasis-med);
    }
  }
  .card__footer--transparent {
    strong {
      @extend .mini-title;
      font-weight: normal;
    }
    .flex--sbc {
      justify-content: stretch;
      align-items: stretch;
      gap: 0.32rem;
      flex-direction: column;
    }
  }
}
.badge-level {
  flex: 1;
  display: table;
  border-collapse: collapse;

  > div {
    @include square(1rem);
    &:first-of-type {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    &:last-of-type {
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
    }

    border: $border;
    display: table-cell;

    &.is-achieved {
      background-color: var(--success);
    }
    background-color: var(--grey1);
  }
}

.badge-tip {
  @extend .mt-xs;
  @extend .mr-xs;

  @include square(1rem);

  color: var(--white);
  border-radius: 50%;
  background-color: var(--grey2);
  z-index: 10;
  cursor: pointer;

  > :first-child {
    @extend .flex--cc;

    height: 100%;
  }

  &.badge-tip--inline {
    display: inline-block;
  }
}

.badge-container {
  transition: none;

  .badge__logo,
  .badge__logo--small {
    position: static !important;
    padding-bottom: 0;
    max-height: auto;
    min-height: auto;
  }

  .badge__logo--small {
    border: 1px solid var(--primary);
  }

  & > * {
    max-width: 100%;
    height: auto;
    border: 4px solid var(--primary);
    border-radius: 50%;
    transition: none;
  }

  .no-stroke,
  .no-stroke circle,
  .no-stroke ellipse,
  .no-stroke line,
  .no-stroke path,
  .no-stroke polygon,
  .no-stroke polyline,
  .no-stroke rect {
    stroke: none !important;
  }

  .stroke-white,
  .stroke-white circle,
  .stroke-white ellipse,
  .stroke-white line,
  .stroke-white path,
  .stroke-white polygon,
  .stroke-white polyline,
  .stroke-white rect {
    stroke: $white !important;
  }

  /** Config SVG colors **/
  .fill-primary {
    fill: var(--secondary);
  }

  .fill-secondary {
    fill: var(--primary);
  }

  .stroke-primary,
  .stroke-primary circle,
  .stroke-primary ellipse,
  .stroke-primary line,
  .stroke-primary path,
  .stroke-primary polygon,
  .stroke-primary polyline,
  .stroke-primary rect {
    stroke: var(--secondary);
  }

  .stroke-secondary,
  .stroke-secondary circle,
  .stroke-secondary ellipse,
  .stroke-secondary line,
  .stroke-secondary path,
  .stroke-secondary polygon,
  .stroke-secondary polyline,
  .stroke-secondary rect {
    stroke: var(--primary);
  }
}
