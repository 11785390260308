.author__name {
  @extend .body-5;
  @extend .body-5-semibold;
  &.has-tip {
    line-height: 1;
    border-bottom: 0;
  }
}
.author-data--big .author__name {
  @extend .body-3;
  @extend .body-3-link;
}
.author-data--small {
  margin-left: 0rem;
}
.author.author--inline {
  a {
    display: inline-flex;
    align-items: center;
  }
  span.author__badge {
    transform: translateY(-2px);
  }
}
.author-data .author__avatar {
  transform: translateY(2px);
}

.author-data__extra {
  @extend .body-5;
  color: var(--emphasis-med);
  a,
  &.a {
    color: var(--primary);
  }
}
