.o-assemblies.o-assemblies--show {
  .card--action .card--list__heading.heading6 {
    @extend .body-2;
  }
  .show-more {
    margin-bottom: 1rem;
  }
  .hide-more {
    text-align: left;
  }
}
