/* Froms foundation overwrites */

$checkboxes-minimum-width: 200px;
$help-text-margin-top: -0.9rem;
.input-group {
  .input-group-field {
    &::placeholder,
    &:focus::placeholder,
    &:focus-within::placeholder {
      @extend .body-4;
      font-size: 16px !important;
      color: var(--emphasis-lowest) !important;
      letter-spacing: normal !important;
      padding-left: 0px;
    }
    &:focus {
      // To raise the focus border on top of any other nearby elements
      position: relative;
      z-index: 10;
    }
  }
}
.remaining-character-count {
  @extend .body-5;
  color: var(--emphasis-low);
}
.input-group-button button {
  height: 2.5rem;
  padding: 0.5rem 1rem;
}

label,
.field {
  &.has-tribute {
    position: relative;

    .tribute-container {
      top: auto;
      margin-top: -$form-spacing;
      max-width: none;
      width: 100%;
    }
  }
}

.help-text {
  display: block;
  line-height: 1.2;
  margin: 0 0 0.875rem;
  &.help-text-form-required-fields {
    margin-top: 0;
  }
  label > & {
    margin-top: -0.8rem;
  }
}

.form-input-extra-before,
.form-input-extra-after {
  display: block;
  font-size: $input-error-font-size;
  font-weight: normal;
  margin-top: $form-spacing * -1;
  @extend .body-5;
  color: var(--emphasis-low);
}

#registration_user_nickname_characters {
  margin-top: 1rem;
}

.form-input-extra-before {
  margin-bottom: $form-spacing * 0.5;
  @extend .body-5;
  color: var(--emphasis-low);
}

.form-input-extra-after {
  margin-bottom: $form-spacing;
}

label > [type="checkbox"],
label > [type="radio"] {
  margin-bottom: 0; //fixes some problems with line-height in double-line labels
}

/* Switch additional styles */

.switch-with-label {
  > label {
    display: flex;
    align-items: center;
    line-height: 1.2;
  }

  .switch-paddle {
    display: inline-block;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  &,
  &.tiny {
    height: auto;
  }
}

.switch {
  input.switch-input {
    &:active,
    &:focus {
      & + .switch-paddle {
        outline: $input-outline-focus;
        outline-color: var(--highlight);
        outline-offset: $input-outline-offset;
      }
    }
  }
}

.checkboxes {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &::after {
    /* Align last row to left */
    min-width: $checkboxes-minimum-width;
    content: "";
  }

  label {
    min-width: $checkboxes-minimum-width;

    input[type="checkbox"] {
      vertical-align: middle;
    }
  }
}

#{text-inputs()},
input[type="file"] {
  height: 3rem;
}

#{text-inputs()},
textarea,
label > textarea,
select,
input[type="file"],
input[type="checkbox"],
input[type="radio"] {
  outline: $input-outline;
  @extend .body-4;

  &:active,
  &:focus {
    outline: $input-outline-focus;
    outline-color: var(--highlight);
    outline-offset: $input-outline-offset;
  }
}

.emoji {
  &__container {
    position: relative;
  }

  &__trigger {
    position: relative;
    top: -$form-spacing;
  }

  &__button {
    position: absolute;
    bottom: $global-margin * 0.5;
    right: $global-margin;
    cursor: pointer;
  }

  &__button svg {
    width: 1em;
  }
}
