#data_picker-title.h2 {
  @extend .body-2;
}
.data-picker {
  margin: 0 0 $global-margin;

  &.picker-single .picker-values div a,
  .picker-prompt a {
    background: white;
    cursor: pointer;
    display: block;
    width: 100%;
    padding: 0.4rem 0.7rem;
    outline: $input-outline;
    border: 1px solid #e8e8e8;
    border-radius: 8px;

    box-shadow: unset;
    -webkit-box-shadow: unset;
    color: var(--emphasis-med) !important;
    &:hover {
      text-decoration: none !important;
    }
    &:active,
    &:focus {
      outline: $input-outline-focus;
      outline-color: var(--primary);
      outline-offset: $input-outline-offset;
      border: 1px solid var(--black-6);
      background-color: #fff;
      box-shadow: 0 0 5px #adadad;
      -webkit-box-shadow: 0 0 5px #adadad;
      transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
      -webkit-transition: border-color 0.25s ease-in-out,
        -webkit-box-shadow 0.5s;
    }

    &::after {
      display: block;
      width: 0;
      height: 0;
      border: inset 5px;
      content: "";
      border-right-width: 0;
      border-color: transparent transparent transparent #1a181d;
      float: right;
      margin-top: 7px;
    }
  }

  &.is-invalid-input.picker-single .picker-values div a,
  &.is-invalid-input .picker-prompt a {
    border-color: $alert-color;
    background-color: #fdeeec;
  }

  &.picker-single .picker-values:not(:empty) + .picker-prompt {
    display: none;
  }
  .picker-values:empty {
    display: none;
  }
  &.disabled.picker-single .picker-values div a,
  &.disabled .picker-prompt a {
    cursor: not-allowed;
    background: #eee;

    &:active {
      border: inherit !important;
      box-shadow: inherit !important;
      -webkit-box-shadow: inherit !important;
      transition: none !important;
      -webkit-transition: none !important;
    }
  }

  &.picker-multiple .picker-values {
    div {
      display: block;
      margin: 0 0 2px;
      font-size: 0.9rem;

      input {
        margin: 2px 8px 8px 2px;
        vertical-align: top;
      }
    }
  }

  .picker-values {
    @extend .tags;
    margin-top: 0.62rem !important;
    margin-bottom: 1rem;
    &:empty {
      margin-bottom: 0;
    }
    .label.primary {
      background-color: var(--grey2);
      color: var(--primary);
    }
  }
}

#data_picker-modal {
  height: 90%;

  .data_picker-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    min-height: 100%;

    .picker-content {
      flex-grow: 10;
      min-height: 100%;
      max-height: 100%;
      overflow: auto;

      .picker-scrollable-content {
        max-height: 100%;
        overflow: auto;
      }
    }
  }
}

.picker-list {
  ul {
    margin-left: 0;

    li {
      list-style: none;
    }
  }
}

.scope-picker {
  &.picker-header {
    padding: rem-calc(16);
    padding-left: 1rem;
    ul {
      display: flex;
    }
    li:hover {
      background: transparent;
    }
    li a::before,
    li ~ li a::before {
      display: none;
    }
    li a::after,
    li ~ li a::after {
      content: $breadcrumbs-item-separator-item;
      width: 10px;
      height: 2em;
      color: var(--emphasis-lowest);
      text-align: center;
      font-weight: 300;
      padding-left: 6px;
      text-decoration: none !important;
      padding-right: 6px;
      float: none;
    }
    li:only-child {
      display: none;
    }
    li:last-child a {
      cursor: default;
      text-decoration: none; /* last active selection */
      color: var(--emphasis-med);
      padding-left: 0;
    }

    a {
      display: block;
      @extend .body-4-link;
    }
  }
  .buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 16;
    .button.muted {
      @extend .btn--disabled;
    }
    .button:first-child:not(.muted) {
      @extend .btn--primary;
    }
    .button {
      @extend .btn--large;
      margin-bottom: 0;
    }
  }

  &.picker-content {
    .picker-scrollable-content {
      scroll-snap-type: mandatory;

      a {
        @extend .body-4;
        @extend .body-4-link;
        scroll-snap-coordinate: 0 0;
        display: block;
        border-radius: $global-radius;
        border-top: none;
        padding: rem-calc(16);

        &:hover,
        &:focus {
          background: var(--white);
          text-decoration: none !important;
          outline: none;
        }
      }
    }
  }
}

#participatory-space-filters {
  margin-bottom: 1rem;
  legend {
    float: left;
    padding-top: 10px;
  }

  .picker-prompt,
  select {
    width: 75%;
    float: right;
    min-height: 35.58px;
  }

  .filters__section {
    padding: 0;
  }

  .picker-values {
    clear: both;

    div {
      display: inline-block;
    }
  }
}
