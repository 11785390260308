// name specific
#profile-tabs {
  // Override foundation
  &.tabs {
    background: transparent;
    border: 0;
    display: flex;
    justify-content: stretch;
    border-bottom: $border;
    margin-bottom: $global-margin;
    position: relative;
    &::before {
      width: 100%;
      height: 3px;
      background-color: var(--grey2);
      position: absolute;
      bottom: 0px;
    }
    .tabs-title {
      float: none;
      flex: 1;
      &.is-active {
        a {
          @extend .body-4;
          background: transparent;
          color: var(--primary);
          background: transparent;
          font-weight: normal;
          border-bottom: 3px solid var(--primary);
          padding-left: 1rem;
          padding-right: 1rem;
          &:hover,
          &:focus {
            text-decoration: none !important;
          }
        }
      }

      a {
        color: var(--emphasis-low);
        padding: 1rem 0.625rem 1.1875rem 0.625rem;
        position: relative;
        text-align: center;
        @extend .body-4;

        &:focus,
        &:hover,
        &[aria-selected="true"] {
          background: transparent;
          color: var(--primary);
          font-weight: normal;
          border-bottom: 3px solid var(--primary);
          text-decoration: none !important;
        }

        &::after {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }
    }
  }
}

// Make the connection between the title and the content
[data-tabs-content="profile-tabs"] {
  border-top: 0;
  background: transparent;
  color: inherit;
  transition: all 0.5s ease;
}

.responsive-tab-block {
  @media only screen and (max-width: 639px) {
    overflow: hidden;
    height: 44px;

    & > ul.tabs {
      flex-direction: column;

      & > li {
        flex: 0 0 auto;

        &.is-active {
          -webkit-box-ordinal-group: -1; // fixes older Safari issues
          order: -1;

          &::before {
            display: block;
            content: " ";
            font-weight: normal;
            float: right;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top-color: var(--emphasis-high) !important;
            transform: translateY(6px);
            margin-top: 20px;
          }

          & > a:focus {
            outline: 0 !important;
          }
        }

        & > a {
          border-bottom: 3px solid transparent;
          color: green;

          &:focus {
            outline: 0 !important;
          }
        }
      }
    }

    &.expanded {
      height: auto;

      & > ul.tabs > li.is-active::before {
        transform: translateY(6px) rotate(180deg);
      }
    }
  }
}
