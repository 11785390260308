//Variables
$order-border: $border;

/* Results per page styles */
.results-per-page {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  z-index: 10;
  .results-per-page__text {
    @extend .body-4;
    color: var(--emphasis-med);
  }

  a:hover {
    color: var(--primary);
    background: var(--primary-10);

    text-decoration: none;
  }

  a:focus {
    outline: var(--primary);
  }
}

.results-per-page__dropdown {
  transform: translateY(-4px);
  z-index: 1;
  .menu {
    margin-top: 0;
    .is-dropdown-submenu-parent > a {
      padding: 1rem 0.5rem;
      margin-left: 0.5rem;
      margin-top: 0.25rem;
    }
  }
}

.results-per-page__dropdown--right {
  margin-left: auto;
}

.results-per-page__action {
  margin-left: auto;
  justify-content: flex-end;

  .button {
    margin-bottom: 0;
  }
}

.results-per-page__tabs {
  display: inline-block;
}

.results-per-page__tab {
  color: $muted;

  &::after {
    content: "";
    display: inline-block;
    height: 0.75em;
    border-left: $border;
    margin: 0 0.2em 0 0.5em;
    vertical-align: middle;
  }

  &.is-active {
    color: var(--primary);
    background: var(--primary-10);
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type::after {
    border-left: none;
  }
}
