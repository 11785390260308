// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source
$progress-radius: 40px;
$progress-height: 1rem;
$progress-background: var(--grey2);

/// Adds styles for a progress bar container.
@mixin ov-progress-container {
  height: $progress-height;
  margin-bottom: $progress-margin-bottom;
  border-radius: $progress-radius;
  background-color: var(--grey2);
}

/// Adds styles for the inner meter of a progress bar.
@mixin ov-progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 1rem;
  background-color: var(--primary);
  min-height: $progress-height;
  &.progress-meter--minimum {
    background-color: var(--grey2) !important;
  }
  @if has-value($progress-radius) {
    border-radius: $progress-radius;
  }
}

/// Adds styles for text in the progress meter.
@mixin ov-progress-meter-text {
  @include absolute-center;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: var(--white);
  white-space: nowrap;
  transform: translate(-42%, -50%);
  @if has-value($progress-radius) {
    border-radius: $progress-radius;
  }
}

// Progress bar

.progress__bar {
  .progress__bar__title .progress__bar__number {
    color: var(--emphasis-low) !important;
  }
  .progress__bar__bar {
    height: 1rem;
    .progress__bar__bar--complete {
      border-radius: $progress-radius;
      background-color: $actions;
      height: 1rem;
    }

    .progress__bar__bar--incomplete {
      background-color: var(--grey2);
      height: 1rem;
      margin-left: 1px;
      border-top-right-radius: $progress-radius;
      border-bottom-right-radius: $progress-radius;
    }
  }

  .progress {
    @include ov-progress-container;
  }
  @each $name, $color in $foundation-palette {
    &.#{$name} {
      .progress-meter {
        background-color: $color;
      }
    }
  }
}

// Inner meter
.progress-meter {
  @include ov-progress-meter;
}

// Inner meter text
.progress-meter-text {
  @include ov-progress-meter-text;
}
