.extra button.follow-button,
.extra a.follow-button {
  @extend .btn;
  @extend .btn--secondary;
  @extend .btn--small;
  @extend .body-5;
  @extend .body-5-medium;
  margin-top: 0;
  display: inline-flex;
  gap: 4px;
  align-items: center;
  width: auto;
  min-width: 71.333%;
  text-wrap: nowrap;
}
.o_participatory_process {
  .section-heading {
    margin-top: 1rem;
  }
}
