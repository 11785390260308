.meeting-polls{
  &__main{
    flex: 1;
    display: flex;
  }

  &__iframe{
    @extend .bg-muted;

    display: flex;

    > *{
      flex: 1;
      border: 0;
    }
  }

  &__aside{
    flex: 0;
    counter-reset: question;

    & + div{
      flex: 1;
    }

    &.is-open{
      flex: 0 0 20%;

      & + div{
        flex: 0 0 80%;
      }

      // shrink iframe when there's two panels
      & + & + div{
        flex: 0 0 60%;
      }

      & + &{
        border-left: 1px solid #e8e8e8;
      }
    }
  }

  &__question{
    background-color: $white;
    border-bottom: 1px solid #e8e8e8;

    summary{
      @extend .padding-1;

      cursor: pointer;
      font-weight: bold;
      color: var(--secondary);
      transition: background-color .2s ease-in-out;

      &:hover{
        background-color: #f4f4f4;
      }

      &::after{
        counter-increment: question;
        content: "#" counter(question);
      }

      & ~ *{
        padding: 0 1rem 1rem;

        // dynamic content
        > :first-child{
          font-weight: 600;
          line-height: 1.25;
        }

        label{
          display: flex;
          align-items: baseline;
          cursor: pointer;
          line-height: 1.25;
        }

        label + label{
          margin-top: $global_margin;
        }
        // end dynamic content
      }
    }

    &[open] .meeting-polls__answer--bar > *{
      opacity: 1;
      transform: translateX(0);
    }

    &.is-new{
      animation: animateHighlight 5s ease-in-out forwards;
    }

    @keyframes animateHighlight{
      0%,
      80%{
        background-color: rgba(var(--warning-rgb), .1);
      }
    }
  }

  &__question--admin{
    @extend .meeting-polls__question;

    summary ~ *{
      border-top: 1px solid #e8e8e8;
      background-color: #f6f6f6;
      padding: 1rem;

      a{
        text-decoration: underline;
        margin-bottom: $global_margin;
        display: block;
      }

      > :first-child{
        font-weight: normal;
      }
    }
  }

  &__answer{
    display: flex;
    align-items: center;

    &--value{
      flex: 0 0 15%;
      font-weight: bold;
    }

    &--bar{
      flex: 0 0 85%;
      height: 10px;
      overflow: hidden;

      > *{
        background-color: var(--primary);
        height: 100%;
        opacity: 0;
      }
    }
  }

  // vertical flow
  label + &__answer + label{
    margin-top: 1.5 * $global_margin;
  }

  &__admin-label{
    background-color: #9675ce;
    color: $white;
    font-weight: bold;
    font-size: 14px;
    padding: 1rem;
  }

  &__admin-action{
    border-top: 1px solid #e8e8e8;
    padding: .5rem 0;
    display: flex;
    align-items: center;

    &:last-child{
      border-bottom: 1px solid #e8e8e8;
    }

    :first-child{
      flex: 0 0 40%;
    }

    :last-child{
      flex: 0 0 60%;
    }
  }
}
