#dc-dialog-wrapper {
  z-index: 1000000;
  button#dc-dialog-reject,
  #dc-dialog-accept {
    min-width: 110px;
    max-width: 50%;
  }
  .dc-dialog {
    position: fixed;
    background-color: $white;
    bottom: 0;
    width: 100%;

    @include breakpoint(large) {
      width: unset;
    }

    .dc-button-wrapper {
      flex-direction: column;
      margin: 1rem 0;
      flex-wrap: wrap;
      gap: 0.5rem;
      > * {
        width: 100%;
        margin: 0;
      }

      @include breakpoint(medium) {
        flex-direction: row;

        > * {
          width: unset;
          margin: 0 1rem;
        }
      }

      @include breakpoint(large) {
        justify-content: flex-end;
      }
    }
  }
}

#dc-modal {
  background-color: $white;

  .category-wrapper {
    background-color: $light-gray;
    padding: 0.2rem;

    .category-row {
      position: relative;
      justify-content: space-between;

      .dc-title {
        &::before {
          border: solid #2d4156;
          border-width: 0 2px 2px 0;
          padding: 0.2em;
          display: inline-block;
          content: "";
          margin-right: 15px;
          position: absolute;
          left: 1.2em;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }

        &.open {
          &::before {
            transform: translateY(-50%) rotate(225deg);
          }
        }
      }

      .dc-switch {
        padding: 0.5rem 0 0;
      }
    }

    .dataconsent-details-wrapper {
      .detail-titles {
        border-bottom: 1px solid $medium-gray;

        > * {
          font-weight: 600;
        }
      }

      .dataconsent-detail-row {
        font-size: 0.6rem;
      }
    }
  }

  .dc-buttons-wrapper {
    justify-content: space-between;
  }
}

.disabled-iframe {
  background-color: $light-gray;
  border: 1px solid $medium-gray;
  cursor: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-height: 20rem;

  &.ql-video {
    display: flex;
  }
}

.dataconsent-warning {
  flex-direction: column;
  font-size: 2rem;
  font-weight: $global-weight-bold;
}

.dc-categories button.dc-title.padding-left-3 {
  padding-left: 3rem !important;
}
.dc-categories .dc-buttons-left {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-direction: row-reverse;
}
.dc-categories button#dc-modal-save {
  min-width: 156px;
}
