.o-notifications_settings,
.o-user_interests {
  strong {
    margin-bottom: 0.65rem;
    margin-top: 0.65rem;
    display: inline-block;
  }
}

.o-account--delete .m-account > .columns {
  width: 100%;
  max-width: 45rem;
}

.o-user_interests .m_user_interests > ul > li > .switch {
  margin-top: 2rem;
  .switch-label {
    @extend .body-4;
    @extend .body-4-semibold;
  }
}

.o-authorizations--index,
.o-own_user_groups {
  h5.card--list__heading {
    @extend .body-2;
  }
  .card.card--list {
    border-radius: 8px;
  }
  .section,
  .main-container__content,
  .card.card--list,
  .card--list__item {
    margin: 0 !important;
    padding: 0 !important;
  }
  .card.card--list a,
  .card.card--list .card--list__text {
    padding: 0;
    margin: 0;
    width: 100%;
    position: relative;
  }
  .card.card--list .card--list__text {
    padding: 0.62rem 1rem;
  }
  .main-container__content .card a:not(:last-of-type),
  .main-container__content
    .card
    .card--list__item:not(:last-child)
    .card--list__text {
    border-bottom: 0;
  }
  .section {
    background-color: var(--grey1);
  }

  .label {
    position: absolute;
  }
}

.o-own_user_groups {
  .card--list__text {
    .text-small {
      display: inline-block;
      width: 100%;
    }
    .label {
      top: 1.4rem;
      margin-left: 1rem;
    }
  }
}
.o-account .m_account--delete p {
  margin-bottom: 1rem !important;
}
.o-account,
.o-notifications_settings,
.o-authorizations--index,
.o-own_user_groups,
.o-user_interests,
.o-data_portability,
.o-download_your_data {
  h1.heading1 {
    @extend .heading-3;
    text-align: center;
  }
  .card {
    border: 0px;
    border-radius: 0;
    box-shadow: unset;
    padding: 0;
    margin: 0;
  }
  .side-panel__tabs .tabs-title {
    border: 0px;
    padding-right: 24px;
    &:first-child {
      padding-top: 0;
    }
  }
  .main-container {
    background-color: transparent;
    border: 0px;
  }

  .main-container__content {
    background-color: var(--white);
    padding: 1.5rem 1rem;
    border-radius: $global-radius;

    @include breakpoint(medium) {
      padding: 1.5rem 1rem;
    }
  }
  .link.change-password {
    font-weight: normal;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  p:not(.help-text) {
    margin-bottom: 0;
  }
  .main-container__content [type="submit"] {
    margin-top: 1.5rem;
  }
}
