:root {
  //DECIDIM DEFAULT VARIABLES
  /*
    --primary: #ef604d;
    --primary-rgb: 239, 96, 77;
    --secondary: #599aa6;
    --secondary-rgb: 89, 154, 166;
    --success: #57d685;
    --success-rgb: 87, 214, 133;
    --warning: #ffae00;
    --warning-rgb: 255, 174, 0;
    --alert: #ec5840;
    --alert-rgb: 236, 88, 64;
    --proposals: #238ff7;
    --actions: #57d685;
    --debates: #fa6c96;
    --meetings: #fabc6c;
    --twitter: #55acee;
    --facebook: #3b5998;
    --google: #dd4b39;
  */

  //Colors/neutrals
  --white: rgb(255, 255, 255);
  --white-50: rgba(255, 255, 255, 0.5);
  --black-60: rgba(0, 0, 0, 0.6);
  --black-10: rgba(0, 0, 0, 0.1);
  --black-6: rgba(0, 0, 0, 0.06);
  --grey1: #f5f5f5;
  --grey2: #e0e0e0;
  --grey5: #707070;

  //Colors/official
  /* Colors/official
     ===
     Our design system supports only ONE color. From this color we compute derivative in
     plain css (@see https://www.smashingmagazine.com/2022/06/simplify-color-palette-css-color-mix/). 

     primary-dark: the primary color mixed with some black. 
     primary-10: white with 18% of primary coor

     NB: We use --on-primary to define the color to be used on the primary color (for contrast purpose).
     NBB: --primary is defined from the template at RENDER time. Choose this color from your decidim admin panel.
  **/
  --primary: #0476d9;
  --primary-dark: color-mix(in srgb, var(--primary), black 10%);
  --primary-10: color-mix(in srgb, var(--primary), white 85%);
  --on-primary: var(--white);
  /** the design system supports only one color. **/
  --secondary: var(--primary);
  --secondary-10: var(--primary-10);
  --on-secondary: var(--on-primary);

  //Colors/status
  --green: var(--success);
  --yellow: var(--warning);
  --yellow-dark: color-mix(in srgb, var(--yellow), black 10%);
  --red: var(--alert);
  --red-dark: color-mix(in srgb, var(--red), black 10%);

  //Colors/text colors
  --emphasis-high: #000000f0;
  --emphasis-med: #000000db;
  --emphasis-low: #000000ad;
  --emphasis-lowest: #0000008f;

  //Miscellaneous
  --font-fallback: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --card-shadow: 0px 6px 16px rgba(0, 0, 0, 0.05);
  --card-corner-radius: 8px;
  --divider-border: 1px solid rgba(0, 0, 0, 0.06);

  //Typography
  --font-family: "Inter";
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}
