.logo-wrapper span {
  color: var(--emphasis-high);
  font-weight: var(--font-weight-bold);
  font-size: 26px;
  display: inline-block;
  text-align: left;
  line-height: 1.2em;
  position: relative;
  padding-left: 0;
  &:hover {
    text-decoration: underline;
  }
}

.logo-wrapper img {
  max-height: 70px;
}

.logo-wrapper span::before {
  content: none;
  display: none;
  position: static;
  border-left: unset;
  height: 0;
  top: 0;
  left: inherit;
}
