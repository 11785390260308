.register__separator{
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;
  font-style: italic;
  margin: 1rem 0 2rem;

  &::before{
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    background-color: $medium-gray;
    position: absolute;
    top: 50%;
    z-index: -1;
  }
}

.register__separator__text{
  display: inline-block;
  background: $light-gray;
  padding: 0 1rem;
}

.register-form{
  input[type="text"],
  input[type="email"],
  input[type="password"]{
    height: 3rem;
  }

  h3{
    display: table;
    font-size: 1.25em;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    margin-top: 0;
    text-align: center;
  }

  label > [type='checkbox']{
    margin-bottom: 1rem;
  }

  .tos-text{
    padding: .5rem;
    border: 1px solid #e8e8e8;
    margin: 1rem 0;
    max-height: 10rem;
    overflow: auto;
    font-size: .8rem;
    font-style: italic;
  }

  #card__tos,
  #card__newsletter{
    text-align: center;
  }
}

.user-nickname{
  label .row{
    > span:first-of-type{
      // this is the prefix div
      @extend .input-group-label;

      padding: 0;
      border-#{$global-right}: 0;
      width: 8%;
      height: 3rem;
      border-top-left-radius: $input-radius;
      border-bottom-left-radius: $input-radius;

      .prefix{
        display: inline-block;
        height: 2.9rem;
        padding-top: .8rem;
      }
    }

    > span:last-of-type{
      // this is the input div
      @extend .input-group-field;

      width: 92%;
      height: 3rem;

      input{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  label.is-invalid-label .row{
    margin-bottom: $form-spacing;

    > span:first-of-type{
      // this is the prefix div
      background-color: mix($input-background-invalid, $light-gray, 8%);
      border-color: $input-error-color;
    }

    > span:last-of-type{
      // this is the input div
      height: 4.2rem;

      .form-error.is-visible{
        margin-left: -8%;
      }
    }
  }

  .help-text{
    margin-top: 1rem;
  }
}
