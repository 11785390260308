// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group abide
////

/// Sets if error styles should be added to inputs.
/// @type Boolean
$abide-inputs: true;

/// Sets if error styles should be added to labels.
/// @type Boolean
$abide-labels: true;

/// Background color to use for invalid text inputs.
/// @type Color
$input-background-invalid: var(--red);

/// Color to use for labels of invalid inputs.
/// @type Color
$form-label-color-invalid: var(--red);

/// Default font color for form error text.
/// @type Color
$input-error-color: var(--red);

/// Default font size for form error text.
/// @type Number
$input-error-font-size: rem-calc(16);

/// Default font weight for form error text.
/// @type Keyword
$input-error-font-weight: normal;

/// Styles the background and border of an input field to have an error state.
///
/// @param {Color} $background [$alert-color] - Color to use for the background and border.
/// @param {Number} $background-lighten [10%] - Lightness level of the background color.
@mixin form-input-error(
  $background: $input-background-invalid,
  $background-lighten: 10%
) {
  &:not(:focus) {
    border-color: $background;
    background-color: var(--white);

    &::placeholder {
      color: $background;
    }
  }
}

/// Adds error styles to a form element, using the values in the settings file.
@mixin form-error {
  display: none;
  margin-top: $form-spacing * -0.5;
  margin-bottom: $form-spacing;

  @extend .body-6;
  @extend .body-6-semibold;
  color: var(--red);
}

@if $abide-inputs {
  // Error class for invalid inputs
  .is-invalid-input {
    @include form-input-error;
  }
}

@if $abide-labels {
  // Error class for labels of invalid outputs
  .is-invalid-label {
    color: var(--emphasis-med);
  }
}

// Form error element
.form-error {
  @include form-error;

  &.is-visible {
    display: block;
  }
}
