// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group label
////

/// Default background color for labels.
/// @type Color
$label-background: $primary-color;

/// Default text color for labels.
/// @type Color
$label-color: $white;

/// Alternate text color for labels.
/// @type Color
$label-color-alt: $black;

/// Coloring classes. A map of classes to output in your CSS, like `.secondary`, `.success`, and so on.
/// @type Map
$label-palette: $foundation-palette;

/// Default font size for labels.
/// @type Number
$label-font-size: 0.8rem;

/// Default padding inside labels.
/// @type Number
$label-padding: 0.33333rem 0.5rem;

/// Default radius of labels.
/// @type Number
$label-radius: 40px;

/// Generates base styles for a label.
@mixin label {
  display: inline-block;
  padding: $label-padding;
  border-radius: $label-radius;

  font-size: $label-font-size;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
}

.label {
  @include label;

  background: var(--grey-2);
  color: var(--emphasis-med);

  &.primary,
  &.secondary,
  &.success,
  &.warning,
  &.danger {
    color: var(--white);
  }
}
.label.primary,
.label.secondary,
.label--primary {
  background: var(--primary);
}
.label.success,
.label--success {
  background-color: var(--green);
}
.label.warning,
.label--warning {
  background-color: var(--yellow);
}
.label.danger,
.label--danger {
  background-color: var(--red);
}
