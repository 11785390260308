//Generic toggle expand

.toggle-show{
  display: none;

  &.is-expanded{
    display: block;
  }
}

.expanded-text{
  .text-toggle__short{
    display: none;
  }

  .text-toggle__long{
    display: block;
  }
}

.text-toggle__long{
  display: none;
}
