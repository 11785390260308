.conversation {
  .conversation-header {
    @extend .p-m;
    justify-content: flex-start !important;
    gap: 1rem;
    border-bottom: $border;

    h1 {
      font-size: $global-font-size;
      font-weight: normal;
    }

    img {
      width: rem-calc(66);
      height: rem-calc(66);
      border-radius: 50%;
      margin-left: 2.5rem;
    }
  }

  .conversation-chat {
    @include flex;
    @include flex-align(left, top);
    @include flexgap($global-margin);

    @extend .mt-s;

    width: 75%;

    &.conversation-chat--offset {
      @include flex-direction(row-reverse);
      @include flexgap($global-margin, row-reverse);

      margin-left: auto;
    }

    > :first-child {
      flex-shrink: 0;
    }

    > div {
      flex: 1;
    }

    .p-s {
      > p:last-child {
        margin-bottom: 0;
      }
    }

    img {
      width: rem-calc(33px);
      height: rem-calc(33px);
      border-radius: 50%;
    }

    .card {
      margin-bottom: $global-margin * 0.5;
      border: 1px solid var(--grey2);
      box-shadow: none;
    }

    .card:last-of-type {
      margin-bottom: 0;
    }
    .text-small {
      @extend .body-6;
    }
  }

  .conversation-reply {
    @extend .mt-s;

    width: 75%;
    .button.secondary.light {
      @extend .btn;
      @extend .btn--primary;
    }
    &.conversation-reply--offset {
      margin-left: auto;
      .medium-offset-3 {
        margin-left: 21%;
        width: 79% !important;
      }
      h5.section-heading {
        @extend .body-3;
        @extend .body-3-medium;
        margin-bottom: 1rem;
      }
    }
  }
}

.conversations {
  .card--list__item {
    align-items: stretch;
  }
}

.conversation-groups {
  .title-action {
    margin-bottom: 0.8rem;
  }
}

// Conversations in user profile pages to full length
.user-activity {
  .conversation {
    .conversation-reply {
      width: 100%;
    }
  }
}

.disabled-tribute-element {
  color: $muted;

  span.disabled-tribute-element-info {
    font-size: rem-calc(12);
    font-weight: normal;
    font-style: italic;
  }
}

.tribute-container {
  .disabled-tribute-element {
    cursor: auto;
    margin: -5px;
    padding: 5px;
    z-index: 10;
  }
}

#user-conversations-add-modal {
  .js-multiple-mentions-recipients label {
    background: white;
    padding: 0.5rem !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.2rem 0;
    gap: 0.5rem;
    cursor: pointer;
    &:hover,
    &:focus {
      color: var(--primary);
      background: var(--primary-10);
      cursor: pointer;
    }
    .float-right {
      font-size: 16px;
      color: var(--primary);
      align-self: baseline;
      flex-grow: 2;
      text-align: right;
    }
  }
}
