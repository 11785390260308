/* Variables */

$timeline-color: var(--primary);
$timeline-inactive-color: var(--emphasis-lowest);

$timeline-padding: 1rem;

/* Timeline */

.timeline {
  list-style: none;
  margin: 2rem 0 0;
}

.timeline__item {
  padding-bottom: 2em;
  position: relative;

  &::before {
    content: "";
    width: 3px;
    height: 1rem;
    background: $timeline-color;
    left: 1rem;
    top: 0;
    position: absolute;
  }

  &::after {
    content: "";
    clear: both;
    display: table;
    width: 100%;
  }

  &:last-of-type::before {
    width: 0;
  }
}

.timeline__phase {
  width: 30px;
  height: 30px;
  margin-left: -13px;
  position: absolute;
  top: 0;
  left: 1rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background: $timeline-color;
  border: 2px solid var(--primary);
  box-shadow: none;
}

.timeline__phase__number {
  display: block;
  text-align: center;
  line-height: 1;
  color: $white;
  font-weight: 600;
  font-size: 1.2em;
  align-self: center;
  margin-left: 0;
  margin-top: 0;
}

.timeline__info {
  width: 90%;
  width: calc(100% - 45px);
  float: right;
  background-color: $white;
  border: $border;

  .timeline__item--current & {
    border-color: $timeline-color;
  }
}

.timeline__header {
  background: $medium-gray;
  padding: $timeline-padding * 0.5 $timeline-padding;

  .timeline__item--current & {
    background-color: $timeline-color;
    color: $white;
  }
}

.timeline__date {
  color: var(--emphasis-low);

  .timeline__item--current & {
    color: var(--emphasis-high);
  }
}
.o_participatory_process .timeline__item--current .timeline__date {
  color: var(--on-primary);
}
.timeline__title {
  margin-bottom: 0;
}

.timeline__content {
  padding: $timeline-padding;

  :last-child {
    margin-bottom: 0;
  }
}

.timeline__footer {
  list-style: none;
  margin: 0;
  border-top: $border;

  @include breakpoint(medium) {
    display: flex;
    flex-wrap: wrap;
  }
}

.timeline__footer-item {
  flex-grow: 1;
  text-align: center;
  padding: 0.5em;
  border-bottom: $border;

  &:last-child {
    border-bottom: 0;
  }

  @include breakpoint(medium) {
    border-bottom: 0;
    border-right: $border;

    &:last-child {
      border-right: 0;
    }
  }
}

.timeline__footer-inner {
  display: block;
}

.timeline__item-icon {
  width: 1.5em;
  height: 1.5em;
}

.timeline__item-text {
  display: block;
  letter-spacing: inherit;
  @extend .body-4;
  @extend .body-4-caps;
  color: var(--emphasis-med);
}

/* Inactive timeline */

.timeline__item--current {
  &::before {
    background: $timeline-inactive-color;
  }
}

.timeline__item--inactive {
  &::before {
    background: $timeline-inactive-color;
  }

  .timeline__phase {
    background: $timeline-inactive-color;
  }

  .timeline__phase__number {
    @extend .body-4;
    color: var(--white);
    text-shadow: 0 1px 2px rgba(black, 0.25);
  }
}
