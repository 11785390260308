//Variables
$order-border: $border;

/* Order by styles */
.diff-view-by,
.order-by{
  margin-bottom: 1rem;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  a:hover{
    text-decoration: underline;
  }

  & + &{
    @include breakpoint(medium){
      margin-left: $global-margin;
    }

    @include breakpoint(large){
      margin-left: $global-margin * 3;
    }
  }
}

.diff-view-by__dropdown,
.order-by__dropdown{
  display: flex;
  flex-wrap: wrap;

  .menu{
    .is-dropdown-submenu-parent > a{
      background: transparent;
      padding: 0;
      margin-left: .5rem;
      margin-top: .25rem;
    }
  }
}

.order-by__dropdown--right{
  margin-left: auto;
}

.order-by__action{
  margin-left: auto;
  justify-content: flex-end;

  .button{
    margin-bottom: 0;
  }
}

.order-by__tabs{
  display: inline-block;
}

.order-by__tab{
  color: $muted;

  &::after{
    content: "";
    display: inline-block;
    height: .75em;
    border-left: $border;
    margin: 0 .2em 0 .5em;
    vertical-align: middle;
  }

  &.is-active{
    color: $anchor-color;
  }

  &:first-of-type{
    padding-left: 0;
  }

  &:last-of-type::after{
    border-left: none;
  }
}
