.o-surveys--show {
  min-height: 90vh;
  .card {
    margin-top: 1rem;
  }
  .card .section {
    width: 100%;
    max-width: 100%;
    .callout {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }
    @include breakpoint(medium large) {
      margin-bottom: 0;
      padding: 0;
    }
    margin-bottom: 0;
    padding: 0;
  }
  h2.section-heading {
    @extend .heading-3;
    text-align: center;
    width: 100%;
    max-width: 55rem;
    color: var(--emphasis-med);
    margin: 0 auto 1rem auto;
  }
  .lead {
    @extend .body-4;
    color: var(--emphasis-high);
  }
  .lead.medium-centered {
    text-align: center;
  }
}
.form.answer-questionnaire {
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    height: 3rem;
  }
  .questionnaire-question {
    font-weight: normal;
    line-height: 1.2;
  }
  .answer.question {
    margin-bottom: 1.5rem;
  }
  .answer-questionnaire__submit {
    justify-content: flex-start;
  }

  thead td {
    font-weight: normal;
    padding: 0.3rem;
  }

  .button {
    margin: 1.5rem 0;
  }
}
