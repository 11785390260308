.footer {
  display: block;
  padding: rem-calc(59) rem-calc(48) rem-calc(32) rem-calc(48);
  background: var(--primary) !important;
  color: var(--on-primary) !important;
  .main-footer,
  .mini-footer {
    background: transparent;
  }
  #LanguageLinks.footer_nav {
    padding-top: 21px !important;
    text-align: right;
  }
  #FooterNavigation .language-choose {
    svg {
      display: none;
    }
    & > ul > li.is-active > a {
      color: var(--primary) !important;

      &::after {
        border-top-color: var(--primary) !important;
      }
    }
    .is-submenu-item {
      padding: 0;
      a {
        padding: 0.6rem;
      }
    }
    & > ul > li > a {
      padding: 0.62rem 0.5rem;
      padding-right: 32px;
      color: var(--white) !important;
      &::after {
        border-top-color: var(--white) !important;
        border-width: 6px;
      }
      @extend .body-4;
      font-weight: normal;
      &:hover,
      &:focus {
        background-color: var(--grey2);
        color: var(--primary) !important;
        &::after {
          border-top-color: var(--primary) !important;
        }
      }
    }
  }

  a {
    @extend .body-4;
    padding: 0;
    &,
    &:hover,
    &:focus {
      color: var(--on-primary) !important;
    }
  }
  #FooterNavigation {
    display: flex;
    max-width: $grid-row-width;
    margin-right: auto;
    margin-left: auto;
    gap: rem-calc(
      128
    ); /* 120 - 2*8. 8 is an accebility padding on links to be more easy to click.  */
    a {
      display: block;
      padding: rem-calc(8) 0;
    }
    .footer_nav {
      & > strong {
        @extend .body-4-semibold;
        padding-bottom: rem-calc(23);
      }
      ul {
        margin: 0;
        padding: 0;
      }
      display: flex;
      flex-direction: column;
      flex: 1;
      padding-top: 2 * $global-margin;
      white-space: nowrap;
    }
    .main-nav {
      padding: 0;
      margin: 0;
    }
    .main-nav ul {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;
      & > li {
        margin: 0;
        padding: 0;
        display: block;
      }
    }
    .footer__navitem {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    @include breakpoint(large down) {
      flex-direction: column;
      gap: 0;
      grid-gap: rem-calc(2);
    }
  }
  .credits {
    display: flex;
    gap: rem-calc(12);
    align-items: center;
    justify-content: flex-start;
    padding-top: rem-calc(59);

    max-width: $grid-row-width;
    margin-right: auto;
    margin-left: auto;

    .credits__column {
      position: relative;
      flex: 1;
      &.credits__column--license {
        flex: 0;
        min-width: rem-calc(117);
      }
      &.credits__column--madeby {
        justify-self: flex-end;
        max-width: 30%;
      }
      @include breakpoint(smallmedium down) {
        &.credits__column--madeby {
          margin-top: 16px;
          max-width: 100%;
          img {
            max-height: 32px;
          }
        }
      }
    }

    @include breakpoint(medium down) {
      padding-top: 0;
      gap: rem-calc(2);
      flex-direction: column;
    }
  }
}
