@mixin typography-medium-modifiers {
  &-medium {
    font-weight: var(--font-weight-medium);
  }
}

@mixin typography-semibold-modifiers {
  &-semibold {
    font-weight: var(--font-weight-semibold);
  }
}

@mixin typography-bold-modifiers {
  &-bold {
    font-weight: var(--font-weight-bold);
  }
}

@mixin typography-caps-modifiers {
  &-caps {
    text-transform: uppercase;
  }
}
@mixin typography-nocaps-modifiers {
  &-nocaps {
    text-transform: initial;
  }
}

@mixin typography-italic-modifiers {
  &-italic {
    font-style: italic;
  }
}

@mixin typography-underline-modifiers {
  &-underline {
    text-decoration: underline;
  }
}

@mixin filters-area {
  .card > .filters__section {
    display: none;
  }
  .input-group-field {
    height: auto;
  }
  .mini-title {
    margin-bottom: 0.5rem;
  }
  .filters__section label {
    @extend .body-5;
    color: var(--emphasis-high);
  }
  .filters__section label[data-global-checkbox],
  .filters__section--general label[data-global-checkbox] {
    font-weight: 600;
    @extend .body-5-semibold;
    color: var(--emphasis-high);
  }
  .card.card--secondary {
    background: var(--white);
    border-color: var(--grey2);
    border-width: 1px;
    border-style: solid;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .mini-title {
      margin-bottom: 0.5rem;
    }
  }
}

@mixin typography-link-modifiers {
  &-link {
    cursor: pointer;
    color: var(--primary) !important;
    text-align: left;

    &:hover {
      color: var(--primary) !important;
      text-decoration: underline !important;
      text-decoration-skip-ink: auto;
    }

    &:focus {
      color: var(--primary) !important;
      text-decoration: underline !important;
      outline: 0 none transparent;
      outline-offset: 0px;
    }
  }
}

@mixin typography-body-common {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);

  @include typography-link-modifiers;
}

@mixin typography-headline-common {
  font-family: var(--font-family);
  font-weight: var(--font-weight-regular);
  line-height: 120%;

  @include typography-link-modifiers;
}
