$card-grid-margin-bottom: map-get($grid-column-gutter, medium);

.card-grid{
  display: flex;
  flex-wrap: wrap;

  &::before{
    width: 0; //fixes a flexbox bug in Safari with the clearfix element
  }

  .column{
    margin-bottom: $card-grid-margin-bottom;
  }

  .card{
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card__content{
    flex-grow: 1;
  }
}
