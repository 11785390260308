.scope-picker.picker-header {
  & li {
    & a {
      &:focus,
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
