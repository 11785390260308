.diff-direction-label {
  display: block;
  @extend .body-4;
  margin-bottom: 0.25rem;
  color: var(--emphasis-lowest);
}
.diff-view-by + div {
  .card {
    box-shadow: none;
    border-radius: 0.35rem;
  }
  .section-heading {
    @extend .body-4;
    color: var(--emphasis-med);
  }
}
.diff {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    background: transparent;
    min-height: 0;
  }

  del,
  ins,
  span {
    white-space: pre-wrap;
  }

  del,
  ins {
    display: block;
    text-decoration: none;
  }

  del strong {
    font-weight: normal;
    background: scale-color($color-removal, $lightness: -8%);
  }

  ins strong {
    font-weight: normal;
    background: scale-color($color-addition, $lightness: -8%);
  }

  li {
    position: relative;
    padding: 0.5rem 1rem 0.5rem 1.5rem;
    margin: 0;

    &.ins,
    &.del {
      .symbol {
        position: absolute;
        left: 0.5rem;
        top: 0.5rem;
        width: 1rem;
      }
    }

    &.ins {
      background: $color-addition;
      color: scale-color($color-addition, $lightness: -75%, $saturation: -75%);
    }

    &.del {
      background: $color-removal;
      color: scale-color($color-removal, $lightness: -75%, $saturation: -75%);
    }

    .diff-comment {
      display: none;
    }

    .diff-block-info {
      background: none repeat scroll 0 0 var(--grey2);
    }
  }
}
