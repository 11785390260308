.floating-helper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.floating-helper__layout {
  position: relative;
}

.floating-helper__trigger {
  position: fixed;
  right: 0;
  display: inline-flex;
  cursor: pointer;
  background-color: $white;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;

  &:active,
  &:focus {
    outline: $anchor-outline-focus;
    outline-color: var(--highlight);
    outline-offset: $anchor-outline-offset;
  }

  @include breakpoint(small only) {
    bottom: $global-margin * 2;
    top: unset;
  }
  @include breakpoint(mediumlarge only) {
    bottom: $global-margin * 2;
    top: unset;
  }
  @include breakpoint(large only) {
    bottom: $global-margin * 2;
    top: unset;
  }

  @include breakpoint(xlarge) {
    top: 106px;
    position: absolute;
  }
}

.floating-helper__text {
  color: var(--primary);
  background-color: var(--primary-10);
  @extend .body-5;
  font-weight: $global-weight-bold;
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  padding: 0.3rem 0.3rem 0.3rem 0.6rem;
}

.floating-helper__icon {
  color: $white;
  background-color: var(--primary);
  padding: 0.21rem $global-padding * 0.5;
}

.floating-helper__wrapper {
  @include flex;
  margin-right: 2rem;
}

.floating-helper__content {
  background-color: var(--primary-10);
  border-top: 5px solid transparent;
  margin-bottom: $global-margin * 3;
  padding: 2rem 1rem;
  width: 100%;

  @include breakpoint(medium) {
    padding: 3rem 2rem;
  }
  .mb-s,
  p:last-child {
    margin-bottom: 0 !important;
  }
}

.floating-helper__content-inner {
  position: relative;
  align-items: center;
  @include breakpoint(medium) {
    & > * {
      margin: 0;
    }
  }
}

.floating-helper__icon-big {
  border-radius: 50%;
  background-color: rgba(var(--secondary-rgb), 0.1);
  flex-shrink: 0;
  color: var(--secondary);

  @include flex;
  @include flex-align($x: center, $y: middle);
  @include square(70px);
  margin-right: 2rem;
}

.floating-helper__bottom {
  text-align: right;

  @include breakpoint(medium) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.floating-helper__link {
  font-weight: $global-weight-bold;
  text-decoration: underline;
}

a.floating-helper__content-close {
  background-color: transparent;
  color: var(--primary) !important;
  flex-shrink: 0;
  cursor: pointer;

  @include flex;
  @include flex-align($x: center, $y: middle);
  @include square(1.125rem);
  transform: translate(-25px, 5px);
  border-radius: 50%;
  &:hover {
    opacity: 0.6;
    color: var(--primary) !important;
    background-color: transparent !important;
    text-decoration: none !important;
  }
  &:active,
  &:focus {
    color: var(--white) !important;
  }
}
