// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group thumbnail
////

/// Border around thumbnail images.
/// @type Border
$thumbnail-border: 4px solid $white;

/// Bottom margin for thumbnail images.
/// @type Length
$thumbnail-margin-bottom: $global-margin;

/// Box shadow under thumbnail images.
/// @type Shadow
$thumbnail-shadow: 0 0 0 1px rgba($black, 0.2);

/// Box shadow under thumbnail images.
/// @type Shadow
$thumbnail-shadow-hover: 0 0 6px 1px rgba($primary-color, 0.5);

/// Transition proprties for thumbnail images.
/// @type Transition
$thumbnail-transition: box-shadow 200ms ease-out;

/// Default radius for thumbnail images.
/// @type Number
$thumbnail-radius: $global-radius;

/// Adds thumbnail styles to an element.
@mixin thumbnail {
  display: inline-block;
  max-width: 100%;
  min-height: 75px;
  margin-bottom: $thumbnail-margin-bottom;

  border: $thumbnail-border;
  border-radius: $thumbnail-radius;
  box-shadow: $thumbnail-shadow;

  line-height: 0;
}

@mixin thumbnail-link {
  transition: $thumbnail-transition;

  &:hover,
  &:focus {
    box-shadow: $thumbnail-shadow-hover;
  }

  image {
    box-shadow: none;
  }
}

.thumbnail {
  @include thumbnail;
}

a.thumbnail {
  @include thumbnail-link;
}
