// @Component
.collapsible-list{
  &.is-filtered{
    @for $i from 1 through 12{
      &.show-#{$i}{
        .collapse:nth-of-type(n+#{$i + 1}),
        > div:nth-of-type(n+#{$i + 1}){
          display: none;
        }
      }
    }

    .collapsible-list__see-more{
      display: inline-block;

      + .text-small{
        padding-top: .15rem;
        padding-left: .2rem;
      }
    }

    .collapsible-list__see-less{
      display: none;
    }
  }

  .collapsible-list__see-less{
    display: inline-block;
    color: #599aa6;
  }

  .collapsible-list__see-less:hover{
    color: #2d4d53;
    text-decoration: underline;
  }

  .collapsible-list__see-more{
    display: none;
    color: $secondary;
  }

  .collapsible-list__see-more:hover{
    color: #2d4d53;
    text-decoration: underline;
  }

  .card__text--paragraph{
    text-decoration: none;
    color: #3d393c !important;
  }

  .card__text--paragraph:hover{
    text-decoration: none;
  }

  > *{
    display: inline-flex;
    margin: 0 0 .5rem;
  }
}
