.o-participatory_process_groups.o-participatory_process_groups--show {
  .section .row .column.text-muted:has(.icon--grid-three-up) {
    display: none;
  }
  .wrapper .section:first-child .ql-editor.ql-reset-decidim {
    padding-bottom: 0.5rem;
    display: inline-block;
  }
  .section .row .mb-s.text-muted {
    margin-bottom: 0.5rem !important;
  }
}
