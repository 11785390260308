.google-map{
  width: 100%;
  height: 200px;
  background-color: $medium-gray;
  margin-bottom: 2rem;

  @include breakpoint(mediumlarge){
    height: 450px;
  }
}

.map__help{
  .skip{
    @include skip-button;
  }
}

.leaflet-container{
  // Keep it below the second level navigation dropdown
  z-index: 5;
}

.map-info{
  background: var(--primary);
  border-radius: $global-radius;

  > div:first-child{ //Map styles container
    > div:first-child{ //Triangle
      border-top-color: var(--primary) !important;
    }

    > div:nth-child(2){
      background-color: transparent !important;
      border-radius: $global-radius !important;
    }
  }

  *{
    box-shadow: none !important;
  }
}

.map-info__content{
  padding-top: 1rem;
  padding-left: 1.2rem;
}

.map__date-address{
  @include breakpoint(medium){
    display: flex;
  }

  margin-bottom: 1rem;

  .card__datetime{
    float: none;
    border-color: var(--primary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 1rem;
    padding: .5em 1em;

    @include breakpoint(medium){
      margin-right: .5rem;
      margin-bottom: 0;
    }
  }

  .address{
    flex: 1;
    margin-bottom: 0;
  }

  .address__icon{
    display: none;

    @include breakpoint(medium){
      display: block;
    }
  }

  .card__extra{
    padding: 0 1rem;
  }
}

.map-info__button{
  text-align: center;

  .button{
    min-width: 12em;
  }
}

.static-map{
  cursor: pointer;
  height: 120px;
  width: 120px;

  .leaflet-popup-pane,
  .leaflet-control{
    cursor: pointer;
  }

  &:focus{
    outline: $anchor-outline-focus;
    outline-color: var(--highlight);
    outline-offset: $anchor-outline-offset;
  }

  &,
  .leaflet-control-attribution{
    a{
      &:hover,
      &:focus{
        text-decoration: none;
        outline: none;
      }
    }
  }

  &,
  &.leaflet-touch{
    .leaflet-control-attribution{
      font-size: .55rem;
    }
  }
}
