.statistics-cell {
  overflow: hidden;
  margin: rem-calc(64) auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: rem-calc(310);

  .statistic__title {
    @extend .body-5;
    @extend .body-5-caps;
    color: var(--emphasis-lowest);
    margin-bottom: 0;
    vertical-align: middle;
  }

  .statistic__data {
    text-align: center;
    padding: 1rem 0.25rem;
    width: calc(50% - 1rem);
    margin: 0;
    position: relative;
    color: var(--grey5);
    &::after {
      border-left-color: var(--primary) !important;
    }

    @include breakpoint(medium down) {
      width: calc(50% - 1rem);
    }

    @include breakpoint(medium large) {
      width: calc(25% - 1rem);
    }
  }

  .statistic__number {
    @extend .heading-3;
    @extend .heading-3-bold;
    color: var(--emphasis-med);
  }
}
