$container-bg: $white;
$container-border: $border;
$container-shadow: 0 2px 7px var(--black-6);
$container-padding-x: rem-calc(22);
$container-padding-y: rem-calc(24);
$container-padding-2x: rem-calc(32);
$container-padding-2y: rem-calc(34);

/* Main container */

.main-container {
  background: $container-bg;
  border: $container-border;

  &.tabbed-container {
    border: 0;
  }
}

.main-container__content {
  padding: $container-padding-y $container-padding-x;

  @include breakpoint(medium) {
    padding: $container-padding-2y $container-padding-2x;
  }
}

/* Main container side panel */

.main-container--side-panel {
  @include breakpoint(medium) {
    display: flex;
  }
}

.side-panel {
  background-color: transparent;
  border-width: 0;
  border-color: transparent;
  @include breakpoint(medium) {
    border-bottom: 0;
    border-right: unset;
  }
}
.tabs {
  background-color: transparent;
}

.tabs-content {
  border: 0 !important;
  padding: 0;
}

.side-panel__tabs {
  border: none;

  .tabs-title {
    border-bottom: $border;
    padding: $global-padding;
    &:last-of-type {
      @include breakpoint(medium) {
        margin-bottom: rem-calc(64);
      }
    }

    > [role="tab"],
    > a {
      @extend .body-4;
      color: var(--emphasis-med);
      border-radius: $global-radius;
      overflow: hidden;
      &:hover,
      &:focus {
        color: var(--primary);
        box-shadow: none;
        background: var(--primary-10);
        text-decoration: none !important;
      }
    }

    &.is-active > [role="tab"],
    &.is-active > a {
      color: var(--primary);
      box-shadow: none;
      background: var(--primary-10);
    }
  }
}

.floating-helper__layout {
  z-index: 1000;
}

.floating-helper__trigger {
  position: fixed !important;
}
