.extra{
  text-align: center;
  margin-bottom: 1rem;

  .button:last-of-type{
    margin-bottom: 0;
  }

  .follow-button{
    margin-top: 1rem;
  }
}

.extra__suport-number{
  display: block;
  font-weight: 600;
  font-size: 3rem;
  line-height: 1;
}

.extra__suport-text{
  display: block;
  line-height: 1;
  font-size: .9rem;
  margin-bottom: .5rem;
}

.extra__date{
  font-size: 3rem;
  line-height: 1;
}

.extra__date-container{
  margin-bottom: 1rem;
}

.extra__month{
  display: block;
  font-size: 1rem;
}

.extra__time{
  display: block;
  font-size: 1.2rem;
  margin: .5rem 0 0;
}

.extra--text{
  text-align: left;
}
