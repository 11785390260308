.o-sortitions.o-sortitions--index {
  @include filters-area;
}
.m_sortitions-sortitions {
  .columns:last-child .section {
    margin-bottom: 0;
    p {
      padding: 0.5rem !important;
      margin-bottom: 0 !important;
    }
  }
  .sortition-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .sortition-details-item {
    .sortition-details-text.sortition-details-title {
      @extend .mini-title;
    }
    br {
      display: none;
    }
    .sortition-details-text:last-child {
      color: var(--emphasis-high);
      display: block;
      padding-top: 0.2rem;
    }
  }
}
.card--sortition {
  .card-data__item > .text-left {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
    line-height: 0;
    gap: 0.1rem;
    strong.heading4 {
      @extend .body-5;
      @extend .card__data-item__value;
    }
    small {
      @extend .card__data-item__label;
      color: var(--emphasis-low);
    }
    small,
    strong {
      line-height: 1.2;
    }
  }

  .card__header {
    margin-top: 1rem;
    width: 100%;
  }
  .author-data {
    margin-left: 0.3rem;
  }
  .author-data__extra {
    display: none;
  }
  .creation_date_status {
    flex: 0.66;
  }
}
