$border: 1px solid var(--grey2);
.definition-data {
  display: flex;
  flex-wrap: wrap;
}

.definition-data__item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.75rem 0.5rem;
  border-bottom: $border;
  flex-basis: 100%;
  width: 100%;

  &:last-child {
    border-bottom: 0;
  }
  .ql-editor-display,
  .ql-editor.ql-reset-decidim {
    text-align: center;
  }
  &.social_networks {
    a {
      text-align: center;
      display: block;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &.expanded {
    display: flex;
    justify-content: space-between;

    > :first-child {
      flex: 1 1 80%;
      min-width: 0;

      div {
        display: inline;

        @include ellipsis();

        .author__name {
          // Allow the ellipsis, otherwise it's not shown
          display: inherit;
          @extend .body-4;
        }
      }
    }

    > :last-child {
      flex: 1 0 40%;
    }

    @include flexgap(0.5rem);
  }
}

.definition-data__item--double {
  display: inline-block;
  flex-basis: 100%;
  width: 100%;
  border-right: 0;

  &:nth-of-type(even) {
    border-right: 0;
  }
}

.definition-data__title {
  display: block;
  letter-spacing: inherit;
  margin-bottom: 0;
  @extend .mini-title;
}

.definition-data__number {
  font-weight: normal;
  @extend .body-4;
  margin-bottom: 0;
  color: var(--emphasis-med);
}

.definition-data__text {
  font-weight: normal;
  @extend .body-4;
  margin-bottom: 0;
  color: var(--emphasis-med);
}
