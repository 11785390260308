.o-debates--index {
  @include filters-area;
  .collection-sort-controls > .column {
    min-width: 50%;
  }
  .dropdown.menu {
    transform: translateY(2px);
    z-index: 9999;
  }
}
.o-debates--show {
  .proposal-status {
    transform: translateY(-2px);
  }
  .cell-announcement {
    margin: 1.5rem 0;
  }
  .card.extra .card__content {
    align-items: center;
  }
  .view-side .card.extra:last-child {
    padding: 0 !important;
  }
  .extra__date-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .extra__date {
      @extend .body-2;
      color: var(--emphasis-med);
      .extra__month {
        @extend .body-4;
      }
    }
    .extra__time {
      @extend .body-4;
      color: var(--emphasis-low);
    }
  }
}

.card--debate {
  .card__icondata ul {
    padding: 0;
  }
  .card-data__item > .card-data__item--multiple {
    padding: 0 1rem;
  }
  .card-data__item > div {
    padding: 0 1.5rem;
  }
  .card__header {
    margin-top: 1.5rem;
    width: 100%;
  }
  &.muted .card__header {
    margin-top: 1rem;
  }
  .author-data {
    margin-left: 0.3rem;
  }
  .card__button {
    flex: 0;
  }
}
