// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group forms
////

/// Color for form labels.
/// @type Color
$form-label-color: $black;

/// Font size for form labels.
/// @type Number
$form-label-font-size: rem-calc(14);

/// Font weight for form labels.
/// @type Keyword
$form-label-font-weight: $global-weight-normal;

/// Line height for form labels. The higher the number, the more space between the label and its input field.
/// @type Number
$form-label-line-height: 1.8;

@mixin form-label {
  display: block;
  margin: 0;
  margin-bottom: 0;
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
  color: var(--emphasis-high);
  &,
  & > * {
    @extend .body-5;
  }
}

@mixin form-label-middle {
  $input-border-width: get-border-value($input-border, width);

  margin: 0 0 $form-spacing;
  line-height: $global-lineheight;
  padding: ($form-spacing * 0.5 + rem-calc($input-border-width)) 0;
}

label {
  @include form-label;

  &.middle {
    @include form-label-middle;
  }
}
