.m_devise-registrations,
.m_devise-sessions,
.m_devise-passwords,
.m_devise-unlocks,
.m_devise-confirmations {
  h1 {
    @extend .heading-2;
    margin-bottom: 1.5rem;
  }
  h3 {
    @extend .body-2;
    margin-bottom: 1.25rem;
  }
  p {
    width: 100%;
  }
  .user-nickname label.is-invalid-label .row > span:first-of-type {
    justify-content: center;
  }
  .actions {
    text-align: center;
  }
  .register-form .actions {
    margin-bottom: 1rem;
  }
  .register-form #registration_user_nickname_characters {
    margin-top: 0.5rem;
    margin-bottom: 0;
    @extend .body-6;
    @extend .body-6-semibold;
  }
  .register-form .user-nickname .help-text {
    margin-top: 0.5rem;
  }
  .register-form .card__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .new_user.register-form .card__content {
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }
}
