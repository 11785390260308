.reveal--embed{
  overflow-y: hidden;

  .embed__code{
    font-family: monospace;
    background: $light-gray-dark;
    padding: 1em;
    border-radius: .3em;
    font-size: 1em;
    overflow-y: auto;
  }
}

$widget-padding: 8px;

body.widget{
  background: #fff;
  padding: $widget-padding;
  margin: 0;

  .participatory-process{
    margin-bottom: $widget-padding;
  }

  .card{
    margin: 0;
  }

  .organization{
    margin-top: $widget-padding;
    text-align: right;
  }
}
