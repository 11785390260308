@import "quill/dist/quill.snow";
@import "stylesheets/decidim/variables";
@import "stylesheets/decidim/utils/settings";

.editor-container{
  margin-bottom: 1.5rem;

  p{
    line-height: $paragraph-lineheight;
    margin-bottom: $paragraph-margin-bottom;
    text-rendering: $paragraph-text-rendering;
  }

  ul,
  ol{
    margin-bottom: $paragraph-margin-bottom;
  }
}

.ql-editor-display{
  ul,
  ol{
    li{
      list-style-type: none;
      margin-bottom: .5rem;

      &::before{
        content: "\2022";
        display: inline-block;
        white-space: nowrap;
        width: 1.2em;
      }

      &.ql-indent-1{
        padding-left: 3em;
      }

      &.ql-indent-2{
        padding-left: 6em;
      }

      &.ql-indent-3{
        padding-left: 9em;
      }

      &.ql-indent-4{
        padding-left: 12em;
      }

      &.ql-indent-5{
        padding-left: 15em;
      }

      &.ql-indent-6{
        padding-left: 18em;
      }

      &.ql-indent-7{
        padding-left: 21em;
      }

      &.ql-indent-8{
        padding-left: 24em;
      }

      &.ql-indent-9{
        padding-left: 27em;
      }
    }
  }

  ol{
    li{
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-0;

      &::before{ content: counter(list-0, decimal) ". "; }

      &.ql-indent-1{
        counter-increment: list-1;
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

        &::before{ content: counter(list-1, lower-alpha) ". "; }
      }

      &.ql-indent-2{
        counter-increment: list-2;
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;

        &::before{ content: counter(list-2, lower-roman) ". "; }
      }

      &.ql-indent-3{
        counter-increment: list-3;
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;

        &::before{ content: counter(list-3, decimal) ". "; }
      }

      &.ql-indent-4{
        counter-increment: list-4;
        counter-reset: list-5 list-6 list-7 list-8 list-9;

        &::before{ content: counter(list-4, lower-alpha) ". "; }
      }

      &.ql-indent-5{
        counter-increment: list-5;
        counter-reset: list-6 list-7 list-8 list-9;

        &::before{ content: counter(list-5, lower-roman) ". "; }
      }

      &.ql-indent-6{
        counter-increment: list-6;
        counter-reset: list-7 list-8 list-9;

        &::before{ content: counter(list-6, decimal) ". "; }
      }

      &.ql-indent-7{
        counter-increment: list-7;
        counter-reset: list-8 list-9;

        &::before{ content: counter(list-7, lower-alpha) ". "; }
      }

      &.ql-indent-8{
        counter-increment: list-8;
        counter-reset: list-9;

        &::before{ content: counter(list-8, lower-roman) ". "; }
      }

      &.ql-indent-9{
        counter-increment: list-9;

        &::before{ content: counter(list-9, decimal) ". "; }
      }
    }
  }
}
