// User profile, notifications & settings

.user-header {
  margin-bottom: $margin-sm;

  @include breakpoint(medium) {
    margin-bottom: $margin-m;
  }
}

.user-header__main {
  @include clearfix;

  display: flex;
  align-items: center;
}

.user-header__avatar {
  width: 60px;

  > img {
    border-radius: 50%;
    width: 40px;
  }
}

.user-header__heading {
  margin-bottom: 0;
}

.user-nickname {
  color: var(--emphasis-low);
  @extend .body-4;

  .user-contact_link {
    margin-left: 0.5rem;
  }
}

.account-notification {
  display: flex;

  &.callout.flash {
    margin-bottom: 1rem;
  }
}

.badge-card__content {
  @extend .card__content;

  display: flex;
  align-items: center;
  gap: 0.62rem;

  .icon {
    height: 1.5em;
    width: 1.5em;
    margin-right: 0.7em;
    vertical-align: middle;
  }
}

.profile--sidebar--title {
  @extend .body-4;
}
.o-profiles,
.o-user_conversations {
  .columns.medium-3 {
    .medium-8.medium-centered {
      text-align: center;

      .text-center {
        display: inline-block;
        padding: 0.6rem 0.3rem;
        width: 100%;
      }
    }
  }
}
.o-user_timeline,
.o-user_activities,
.o-profiles {
  form.new_filter > .columns,
  .filters__section {
    padding: 0;
    margin: 0;
  }
  .medium-9 {
    padding-left: 0;
    padding-right: 0;
  }
  .medium-centered {
    text-align: center;

    .button {
      margin-bottom: 1rem;
      width: 100%;
      &.follow-button {
        @extend .btn;
        @extend .btn--primary;
        @extend .btn--medium;
        text-wrap: nowrap;
      }
    }
  }
}
.profile--sidebar {
  margin-top: 0px;
  border-radius: $card-border-radius;
  .user-report_link,
  .user-contact_link {
    display: inline-block;
    a,
    button {
      padding: 0.6rem;
      padding-left: 0;
      @extend .body-4-link;
      &:hover {
        cursor: pointer;
        opacity: 0.67;
      }
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
  .card__content {
    padding: 1rem;
    padding-bottom: 0;
  }
  .card__support {
    strong {
      font-weight: normal;
    }
    .card__text--separated-mid-dot a {
      width: 100%;
      display: inline-block;
      padding: 0.2rem 0;
    }
  }
  .card__footer .flex--cc.p-s {
    margin: 1rem 0rem;
    justify-content: space-around;
    a {
      text-align: center;
      @extend .mini-title;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &:hover,
      &:focus {
        color: var(--emphasis-low);
        text-decoration: none;
      }
      &:hover .heading2 {
        text-decoration: underline;
      }
      .heading2 {
        @extend .heading-3;
        @extend .heading-3-bold;
        @extend .heading-3-link;
      }
    }
  }
  .card__image.card__image--larger {
    position: static !important;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
  }
  .text-small {
    @extend .body-4;
    color: var(--emphasis-low);
  }
  small {
    @extend .body-5;
    color: var(--emphasis-low);
  }
  a {
    @extend .body-5;
    display: block;
  }
}
